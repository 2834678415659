import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";

import { Link, useParams } from "react-router-dom";
import context from "react-bootstrap/esm/AccordionContext";

const Personaldetails5idcards1 = () => {
  const { userRegisterObject } = useContext(context);

  const Api = "http://localhost:9000/financia_banks";
  // const [userCountry, setUsercountry] = useState("Select");
  const params = useParams();
  const [carta, setCarta] = useState([]);
  const [setSearchQuery] = useState("");
  const [setFilteredCards] = useState(carta);
  const [ProductData, setProductData] = useState({});
  const [Next] = useState(false);
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = carta.filter((card) =>
      card.title.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCards(query.trim() === "" ? carta : filtered);
  };

  const [eneble, setEneble] = useState("");
  const [cheking, setCheking] = useState("");
  const [savingg, setSaving] = useState("");

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  const MandatoryInfoFunc = () => {
    if (eneble === "" || cheking === "" || savingg === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };
const UserData = JSON.parse(localStorage.getItem("login"));

   const SaveFunc = () => {
     const UpdatesKeyValues = {
       ...UserData?.personal?.personalbanks,
       eneble: eneble,
       cheking: cheking,
       saving: savingg,
     };

     localStorage.setItem(
       "login",
       JSON.stringify({
         ...UserData,
         personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
       })
     );

     axios
       .patch(`http://localhost:9000/users/${UserData.id}`, {
         ...UserData,
         personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
       })

       .then(() => alert(`Ok`))
       .catch((error) => {
         alert("error");
         console.log(error);
       });
   };

  const HandleNext = () => {
    // setNext(true);
    if (eneble !== "" && cheking !== "" && savingg !== "" && !Next ) {
      userRegisterObject.personal.personalbanks.eneble = eneble;
      userRegisterObject.personal.personalbanks.cheking = cheking;
      userRegisterObject.personal.personalbanks.saving = savingg;

      return (
        <Link
          className="personal_btn_button financial_button"
          to={`${window.location.origin}/${Login.id}/singleproduct/step3/${ProductData.id}`}
        >
          Next
        </Link>
      );
    } else {
      return (
        <button className="financial_button_btn">
          Next <i className="bx bx-right-arrow-alt" id="financial_i"></i>{" "}
        </button>
      );
    }
  };

  useEffect(() => {
    axios
      .get(Api)
      .then((res) => setCarta(res.data))
      .catch((err) => console.log(err));
    axios.get(`${Api}/${params.productId}`).then((response) => {
      const data = response.data;
      setProductData(data);
    });
  }, []);

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">5</button> Financial
            Position
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="Financial_id_body">
              <div className="ProductData_div" key={ProductData.id}>
                <div className="carta_div">
                  <div className="carta_images">
                    <img
                      className="finance_carta_img"
                      src={
                        process.env.PUBLIC_URL + "/images/" + ProductData.image
                      }
                      alt=""
                    />
                  </div>
                  <p className="carta_div_p">{ProductData.title}</p>
                </div>
              </div>

              <div className="personalid_onlineid_1_cards">
                <div className="enebleall">
                  <p>Enable all accounts</p>
                  <input
                    type="checkbox"
                    name="1"
                    id="input"
                    value={eneble}
                    onClick={(e) => setEneble(e.target.name)}
                  />
                </div>
                <div className="cash_cheking">
                  <div className="cashcheking_title">
                    <h6>Cash</h6>
                  </div>

                  <div className="cash_cheking_text">
                    <div className="cash_text_1">
                      <p className="cash_text_p"> {ProductData.title_1} </p>
                      <p className="cash_text_p"> {ProductData.text} </p>
                      <p className="cash_text_p"> {ProductData.price} </p>
                    </div>
                    <input
                      type="checkbox"
                      name="2"
                      id="input"
                      value={cheking}
                      onClick={(e) => setCheking(e.target.name)}
                    />
                  </div>
                  <hr />
                  <div className="cash_cheking_text">
                    <div className="cash_text_1">
                      <p className="cash_text_p"> {ProductData.title_2} </p>
                      <p className="cash_text_p"> {ProductData.text} </p>
                      <p className="cash_text_p"> {ProductData.seving} </p>
                    </div>
                    <input
                      type="checkbox"
                      name="3"
                      id="input"
                      value={savingg}
                      onClick={(e) => setSaving(e.target.name)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="financial_buttons">
              <Link
                className="financial_link"
                to={`${window.location.origin}/${Login.id}/singleproduct/step1/1`}
              >
                <button className="financial_previous">
                  <i className="bx bx-left-arrow-alt" id="financial_i"></i>
                  Previous{" "}
                </button>
              </Link>
              <div>
                {/* {HandleNext()} */}
                <Link
                  className="personal_btn_button financial_button"
                  to={`${window.location.origin}/${Login.id}/singleproduct/step3/${ProductData.id}`}
                >
                  Next
                </Link>
              </div>
            </div>
            <div className="personal_buttons">
              {MandatoryInfoFunc()}

              <div className="personal_btn">
                <Link
                  to={`${window.location.origin}/${Login.id}/singleproduct/step1/1`}
                >
                  <button disabled className="finanse_coutine_btn">
                    Back
                  </button>
                </Link>
                <Link to={""}>
                  <button
                    className="signing_button_payments"
                    onClick={() => SaveFunc()}
                  >
                    Save
                  </button>
                </Link>

                <Link to={""}>
                  <button disabled className="finanse_coutine_btn">
                    Continue
                  </button>
                </Link>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails5idcards1;
