import React, { useEffect, useState } from "react";
import "./FinancialpositionIdcards9.css"
import Loannavbar from "../../../components/Loannavbar/Loannavbar";

import steep_9 from "../../../Images/steep_9.png"
import axios from "axios";
import { useParams } from "react-router-dom";

const FinancialpositionIdcards9 = () => {

   const Login = JSON.parse(localStorage.getItem("login"));

   if (Login === null || Login === undefined) window.location.replace("/");

  
   const UserId = JSON.parse(localStorage.getItem("login"));
   const [User, setUser] = useState({});

   useEffect(() => {
     const UserData = JSON.parse(localStorage.getItem("login"));

     axios
       .get(`http://localhost:9000/users/my_balance/${UserId.id}`)
       .then((res) => setUser(res.data))
       .catch((error) => {
         setUser(UserData);
         console.log(error);
       });
   }, []);

  console.log(User);
  

    const Api = "http://localhost:9000/financia_banks";
    const params = useParams();
    const [setCarta] = useState([]);

    const [ProductData, setProductData] = useState({});
    useEffect(() => {
      axios
        .get(Api)
        .then((res) => setCarta(res.data))
        .catch((err) => console.log(err));
      axios.get(`${Api}/${params.productId}`).then((response) => {
        const data = response.data;
        setProductData(data);
      });
    }, []);


  
  return (
    <React.Fragment>
      {" "}
      <Loannavbar />
      <div className="Personaldetails">
        <div className="congratulations">
          <img className="congratulations_img" src={steep_9} alt="" />
          <div className="congratulations_text">
            <h3>Congratulations!</h3>
            <p>Your home loan application is submitted successfully.</p>
          </div>
          <div className="congratulations_carts">
            <div className="congrat_carts_div">
              <div className="congrat_carts_div1">
                <p className="congrat_carts_div1_p">Property worth</p>
                <h6 className="congrat_carts_div1_h6">
                  £ {User?.banks?.my_balance?.loan_home ?? "0"}
                </h6>
              </div>
              <div className="congrat_carts_div1">
                <p className="congrat_carts_div1_p">Outstanding balance</p>
                <h6 className="congrat_carts_div1_h6">
                  £{" "}
                  {Number(ProductData.price_1) +
                    Number(ProductData.price_2) +
                    Number(ProductData.price)}
                </h6>
              </div>
              <div className="congrat_carts_div1">
                <p className="congrat_carts_div1_p">Rate of interest (P.A)</p>
                <h6 className="congrat_carts_div1_h6">
                  {User?.banks?.my_balance?.loan_stavka ?? "0"} %
                </h6>
              </div>
            </div>
            <div className="congrat_carts_div">
              <div className="congrat_carts_div1">
                <p className="congrat_carts_div1_p">Loan amount</p>
                <h6 className="congrat_carts_div1_h6">
                  £ {User?.banks?.my_balance?.home_price ?? "0"}
                </h6>
              </div>
              <div className="congrat_carts_div1">
                <p className="congrat_carts_div1_p">Duration</p>
                <h6 className="congrat_carts_div1_h6">
                  £ {User?.banks?.my_balance?.loan_years ?? "0"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="personal_footer">©2022 CBS</p>
    </React.Fragment>
  );
};

export default FinancialpositionIdcards9;
