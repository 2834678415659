import React, { useContext, useEffect } from "react";
import "./Extemalacounts.css";

// import { Link } from "react-router-dom";

import Offcanvasmodal from "../Offcanvasmodal/Offcanvasmodal.jsx";

import MyVerticallyCenteredModal from "../modal";

import { useState } from "react";

import Footer from "../Footer/Footer.jsx";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Context } from "../../Context";


import Offstikesmodal from "../Offcanvasmodal/Offstikesmodal"

const Extemalacounts = () => {
  const [modalShow, setModalShow] = useState(false);


  const { userRegisterObject } = useContext(Context);

  const [countrycode, setCountrycode] = useState("");
  const [institutionId, setInstitutionId] = useState("");
  const [ terms, setTerms ] = useState("")


  const MandatoryInfoFunc = () => {
    if (countrycode === "" || institutionId === "" || terms === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };


  const HandleNext = () => {
    // setNext(true);
    if (countrycode !== "" && institutionId !== "" && terms !== ""  ) {
      userRegisterObject.countrycode = countrycode;
      userRegisterObject.institutionid = institutionId;
      userRegisterObject.terms = terms;

      return (
        <Link
          className="add_button_submit Identification_link_but"
          onClick={() => setModalShow(true)}
          
        >
          Submit
        </Link>
      );
    } else {
      return <button className="add_button_submit">Submit</button>;
    }
  };


  const params = useParams();
  const [User, setUser] = useState({});
  const [Transfer, setTransfer] = useState(0);
  const [PayTo] = useState("Select");
  const Login = JSON.parse(localStorage.getItem("login"));

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  const TransferFunc = (e) => {
    setTransfer(e.target.value);
    if (e.target.value > User.banks?.balance) {
      setTransfer(User.banks?.balance);
      // console.log(Transfer);
    }
  };

  const RandomAccount = () => {
    const random = Math.floor(Math.random() * 99999999);

    if (random >= 10000000 && random <= 99999999) {
      return random;
    } else {
      RandomAccount();
    }
  };

  const PaymentFunc = () => {
    const isAgree = window.confirm(`Are your sure? ${Transfer}`);

    if (isAgree) {
      axios
        .patch(`http://localhost:9000/users/${params.userId}`, {
          banks: {
            ...Login.banks,
            balance: Number(User.banks?.balance) - Number(Transfer),
            transaction: [
              {
                title: PayTo,
                paid: Transfer,
                account: RandomAccount(),
              },
              ...Login?.banks?.transaction,
            ],
          },
        })
        .then(() => {
          axios
            .get(`http://localhost:9000/users/${params.userId}`)
            .then((data) => {
              localStorage.setItem("login", JSON.stringify(data.data));
              window.location.reload();
            });
        });
    }
  };

  useEffect(() => {
    Login ?? window.location.replace("/");
    if (Login) {
      setUser(Login);
    }
  }, []);


  
const UserData = JSON.parse(localStorage.getItem("login"));
 const SaveFunc = () => {
   const UpdatesKeyValues = {
     ...UserData?.personal?.personalbanks,
     countrycode: countrycode,
     institutionid: institutionId,
     terms: terms,
   };

   localStorage.setItem(
     "login",
     JSON.stringify({
       ...UserData,
       personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
     })
   );

   axios
     .patch(`http://localhost:9000/users/${UserData.id}`, {
       ...UserData,
       personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
     })
     .then(() => alert(`Ok`))
     .catch((error) => {
       alert("error");
       console.error(error);
     });
 };

  return (
    <>
      <div className="Extemalacounts">
        <Offstikesmodal />

        <>
          <Offcanvasmodal />
        </>

        {/* NAV */}

        <div className="Extemalacounts_card">
          <div className="ectemal_acount_exen">
            <div className="main_acout_card_exen">
              <div className="acount_bank">
                <p>Main Account</p>
                <h4>Novus Bank Account </h4>
                <p> 83 4323 4323 3432 3432</p>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_exten">
                    Transfer Funds
                  </button>
                </Link>
              </div>
              <div className="acount_funds">
                <p>Available Funds</p>
                <h3 className="acount_funds_h3">
                  {" "}
                  {Number(User.banks?.balance)} £
                </h3>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_33">
                    Link Accounts
                  </button>
                </Link>
              </div>
            </div>

            <div className="new_acaount_card_exen">
              <h6>Create New Account (TM)</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                incididunt ut labore et dolore{" "}
              </p>
              <button
                className="main_acounts_button_exten_1"
                onClick={HandleLogout}
              >
                Add New Accounts
              </button>
            </div>
          </div>

          <div className="add_extmal_acaound">
            <h6>Add External Account</h6>

            <div className="add_country_code">
              <div className="add_country">
                <p>Country Code </p>
                <input
                  className="add_country_input"
                  type="text"
                  placeholder="Select"
                  onChange={(e) => setCountrycode(e.target.value)}
                />
              </div>
              <div className="add_institution">
                <p>Institution ID </p>
                <input
                  className="add_country_input"
                  type="text"
                  placeholder="Select"
                  onChange={(e) => setInstitutionId(e.target.value)}
                />
              </div>
            </div>

            <div className="add_terams">
              <div className="add_signing">
                <div className="add_signing_p">
                  <input
                    type="checkbox"
                    name="1"
                    id="add_signing_input"
                    onClick={(e) => setTerms(e.target.name)}
                  />
                  <p>Signing In , I agree to</p>
                  <p className="acaund_p">Terms and Conditions. </p>
                </div>
                {MandatoryInfoFunc()}

                <div className="add_buttons">
                  <button
                    className="add_button_submit"
                    onClick={() => SaveFunc()}
                  >
                    Save
                  </button>
                  {HandleNext()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Terms and Conditions"}
          body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
        />

        {/* Bottom  text */}

        <Footer />
      </div>
    </>
  );
};



export default Extemalacounts;

