import React from 'react'

const Footerwhite = () => {
  return (
    <>
      <div className="footer_block_white">
        <p>© All Rights Reserved. HCL Technologies</p>
        <div className="footer_p">
          <p className="footer_par_1"> Privacy Policy</p>
          <p className="footer_par_2">| Terms of Use</p>
          <p className="footer_par_3">| Cookies Policy</p>
          <p className="footer_par_4">| Legal Statement</p>
          <p className="footer_par_5">| Careers </p>
          <p className="footer_par_6">| Corporate Social</p>
          <p className="footer_par_7">| Responsibility</p>
        </div>
      </div>
    </>
  );
}

export default Footerwhite
