import React, { useContext, useState } from "react";
import "./Creation.css";
import logo from "../../Images/logo 1.png";

import { Link } from "react-router-dom";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";

import { Context } from "./../../Context";

import Footer from "../../components/Footer/Footer.jsx";

const Creation = () => {
  const { userRegisterObject } = useContext(Context);

  // const url = "http://localhost:9000/mobile";
  // const API = "http://localhost:9000/";

  const [userGarder, setUsergarder] = useState("Gender");
  const [userCountry, setUsercountry] = useState("Select");
  const [userFirtsname, setUserfirtsname] = useState("");
  const [userAddress, setUseraddress] = useState("");
  const [userLastname, setUserlastname] = useState("");
  const [userBirthday, setUserbirthday] = useState("");

  const MandatoryInfoFunc = () => {
    if (
      userGarder === "" ||
      userCountry === "" ||
      userFirtsname === "" ||
      userAddress === "" ||
      userLastname === "" ||
      userBirthday === ""
    ) {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

const HandleLogout = () => {
  localStorage.removeItem("login");
  window.location.replace("/");
};

  const HandleNext = () => {
    if (
      userCountry !== "" &&
      userGarder !== "" &&
      userFirtsname !== "" &&
      userAddress !== "" &&
      userLastname !== "" &&
      userBirthday !== ""
    ) {
      userRegisterObject.gender = userGarder;
      userRegisterObject.country = userCountry;
      userRegisterObject.first_name = userFirtsname;
      userRegisterObject.address = userAddress;
      userRegisterObject.last_name = userLastname;
      userRegisterObject.birthday = userBirthday;

      userRegisterObject.personal.first_name = userFirtsname;
      userRegisterObject.personal.last_name = userLastname;
      userRegisterObject.personal.birthday = userBirthday;
      userRegisterObject.personal.gender = userGarder;
      userRegisterObject.personal.country = userCountry;
      userRegisterObject.personal.currentAddress = userAddress;

      return (
        <Link className="Identification_link_but" to={"/Success"}>
          Continue
        </Link>
      );
    } else {
      return "Continue";
    }
  };

  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="signing_creation">
        <h5 className="signing_h1_creation">Signing up for Online Banking</h5>
        <p className="signing_p_creation">
          Tell us about your basic details and account requirements
        </p>
        <div className="cretion_d_flex">
          <div className="title_select_groups me-3">
            <div className="title_select_butonn">
              <h6>Gender </h6>
              {[SplitButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={userGarder}
                  // value={"garder"}
                  onChange={(e) => setUsergarder(e.target.value)}
                >
                  <Dropdown.Item
                    onClick={(e) => setUsergarder(e.target.outerText)}
                    eventKey="1"
                  >
                    {" "}
                    Male{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsergarder(e.target.outerText)}
                    eventKey="2"
                  >
                    Female
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsergarder(e.target.outerText)}
                    eventKey="3"
                  >
                    Other
                  </Dropdown.Item>
                </DropdownType>
              ))}
            </div>
          </div>
          <div className="title_select_groups">
            <div className="title_select_butonn">
              <h6>Country </h6>
              {[SplitButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={userCountry}
                  // value={"country"}
                  onChange={(e) => setUsercountry(e.target.value)}
                >
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="1"
                  >
                    Uzbekistan
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="2"
                  >
                    Russia
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="3"
                  >
                    Kazakhstan
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="4"
                  >
                    Kyrgyzstan
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="5"
                  >
                    Tajikistan
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="6"
                  >
                    Turkey
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="7"
                  >
                    Arabic
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => setUsercountry(e.target.outerText)}
                    eventKey="8"
                  >
                    American
                  </Dropdown.Item>
                </DropdownType>
              ))}
            </div>
          </div>
        </div>
        <div className="signing_up_creation">
          <div className="title">
            <div className="title_select">
              <h6 className="creation_h6">First Name </h6>
              <input
                className="title_cration_input"
                type="text"
                placeholder="Enter first name"
                value={userFirtsname}
                onChange={(e) => setUserfirtsname(e.target.value)}
              />

              <h6 className="creation_h6">Address</h6>
              <input
                className="title_cration_input"
                type="text"
                placeholder="Enter address "
                value={userAddress}
                onChange={(e) => setUseraddress(e.target.value)}
              />
            </div>

            <div className="title_firtsname">
              <h6 className="creation_h6">Last Name </h6>
              <input
                className="title_cration_input"
                type="text"
                placeholder="Enter last name"
                value={userLastname}
                onChange={(e) => setUserlastname(e.target.value)}
              />

              <h6 className="creation_h6">Date of Birth * </h6>

              <input
                className="title_select_date"
                type="date"
                value={userBirthday}
                onChange={(e) => setUserbirthday(e.target.value)}
              />
            </div>
          </div>

          <div className="creation_text">
            <h6>Benefits of Online Banking</h6>
            <p>
              Check recent account activity and access account balances from one
              convenient location.
            </p>
            <p>
              Transfer funds between your Novus accounts and accounts at other
              banks.
            </p>
            <p>Go paperless with up to 25 months of deposit statements.</p>
            <h6>Need Assistance?</h6>
            <p>
              For Online Banking technical assistance, Bill Pay support, or
              general account inquiries
            </p>
            <p>Call 1-877-768-2265.</p>
          </div>
        </div>

        {MandatoryInfoFunc()}

        <div className="creation_button">
          <Link to={"../Verificationselfie"}>
            <button className="vreification_b">Back</button>
          </Link>

          {/* <Link to={"../Success"}> */}
          <button className="signing_button_v">{HandleNext()}</button>
          {/* </Link> */}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Creation;
