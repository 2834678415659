import React from "react";
import "./Successcrate.css";

import logo from "../../Images/logo 1.png";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";

const Successcrate = () => {
  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="successcreate">
        <h5 className="signing_h1">Signing up for Online Banking</h5>

        <div className="successcrate_block">
          <div className="successcreate_title">
            <i
              id="ii"
              className="bx bxs-select-multiple bx-rotate-90 bx-tada"
            ></i>
            <h5 className="successcreate_title_h5">
              Credential successfully created{" "}
            </h5>
          </div>

          <p>
            Your Novus Online and Mobile Banking Credential has been Successfuly
            Created.
          </p>

          <p>
            {" "}
            Please now use your new Online and Mobile Banking credential and
            password to log into Online Banking.
          </p>
          <Link to={"/"}>
            <button className="successcreate_button">
              Login into Online Banking
            </button>
          </Link>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Successcrate;
