import React, { useContext, useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import axios from "axios";
import { Context } from "../../../Context";

const Personaldetails2 = () => {

  const { userRegisterObject } = useContext(Context);

  const UserData = JSON.parse(localStorage.getItem("login"))

  const [postcode, setPostcode] = useState("");
  const [ homenumber, setHomenumber ] = useState("")
  
  const MandatoryInfoFunc = () => {
    if (
      postcode === "" || homenumber === ""
    ) {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };


  const HandleNext = () => {
    if (postcode !== "" && homenumber !== "") {
      userRegisterObject.personal.personalbanks.postcode = postcode;
      userRegisterObject.personal.personalbanks.home_number = homenumber;
      return (
        <Link
          className="signing_button_payments lin_personal_decoration"
          to={`${window.location.origin}/${Login.id}/Employmentdetails`}
        >
          Continue
        </Link>
      );
    } else {
      return <button className=" signing_button_payments">Continue</button>;
    }
  }


  const [userCountry, setUsercountry] = useState("Select");
  const [setPersonaluser] = useState([]);
  const [User, setUser] = useState({});

  const SaveFunc = () => {
    const UpdatesKeyValues = {
      ...UserData?.personal?.personalbanks,
      postcode: postcode,
      home_number: homenumber,
    };

    localStorage.setItem(
      "login",
      JSON.stringify({
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
    );

    axios
      .patch(`http://localhost:9000/users/${UserData.id}`, {
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
      .then(() => alert(`Ok`))
      .catch((error) => {
        alert("error");
        console.error(error);
      });
  }

  useEffect(() => {
    // const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users`)
      .then((res) => setPersonaluser(res.data))
      .catch((err) => console.log(err));
    setUser(UserData);
  }, []);
  
  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">2</button> Current Contact
            Details
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="personal_applicat_steep_1">
              <div className="applicant">
                <div className="aplicant_role">
                  <p>Current residential address </p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder=" 8-10 Lampton Rd, Hounslow TW3 1JL"
                    value={User?.personal?.currentAddress}
                  />

                  <p>Time at Address * </p>
                  <div className="contactdelis_timeadress">
                    {" "}
                    <div className="contactdelis_input">
                      <input
                        className="input_personal_postinput"
                        type="text"
                        placeholder="20"
                        value={User?.banks?.my_balance?.loan_years}
                      />
                      <h6>Years</h6>
                    </div>
                    <div className="contactdelis_input">
                      <input
                        className="input_personal_postinput"
                        type="text"
                        placeholder=" 24"
                        value={User?.banks?.my_balance?.loan_to}
                      />
                      <h6>Months</h6>
                    </div>
                  </div>
                </div>

                <div className="co_aplicant">
                  <p>Postcode </p>
                  <input
                    className="input_personal_postcode"
                    type="text"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    placeholder=" Postcode  "
                  />
                </div>
              </div>

              <div className="Ownership"></div>
            </div>

            <div className="personal_applicat_steep_2">
              <h6 className="personal_h6">Primary Applicant</h6>

              <div className="applicant">
                <div className="aplicant_role">
                  <div className="title_select_butonn">
                    <p>Country </p>
                    {[SplitButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={User?.personal?.country}
                        // value={"country"}
                        onChange={(e) => setUsercountry(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="1"
                        >
                          Uzbekistan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="2"
                        >
                          Russia
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="3"
                        >
                          Kazakhstan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="4"
                        >
                          Kyrgyzstan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="5"
                        >
                          Tajikistan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="6"
                        >
                          Turkey
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="7"
                        >
                          Arabic
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setUsercountry(e.target.outerText)}
                          eventKey="8"
                        >
                          American
                        </Dropdown.Item>
                      </DropdownType>
                    ))}
                  </div>

                  <p className="work_number">Home phone number</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="+44 2 3451 232"
                    value={homenumber}
                    onChange={(e) => setHomenumber(e.target.value)}
                  />
                </div>

                <div className="co_aplicant">
                  <p>Email </p>

                  <input
                    className="input_personal_sign"
                    type="email"
                    placeholder="david.wisner@gmail.com"
                    value={User?.email}
                  />
                  <p>Mobile number</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="+44 2 3451 764"
                    value={User?.phone_number}
                  />
                </div>
              </div>
              <div className="personal_buttons">
                {MandatoryInfoFunc()}
                <div className="personal_btn">
                  <Link
                    to={`${window.location.origin}/${Login.id}/Personaldetails`}
                  >
                    <button className="payments_back_b">Back</button>
                  </Link>
                  <Link to={""}>
                    <button
                      className="signing_button_payments"
                      onClick={() => SaveFunc()}
                    >
                      Save
                    </button>
                  </Link>
                  {HandleNext()}
                  {/* <Link
                    to={`${window.location.origin}/${Login.id}/Employmentdetails`}
                  >
                    <button className="signing_button_payments">
                      Continue
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails2;
