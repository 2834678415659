import React from "react";
import "./Loansstep1.css";

import { Link } from "react-router-dom";


import MyVerticallyCenteredModal from "../modal";

import { useState } from "react";

import Footer from "../Footer/Footer";
import Offcanvasmodal from "../Offcanvasmodal/Offcanvasmodal";

import Offstikesmodal from "../Offcanvasmodal/Offstikesmodal"

const Loansstep1 = () => {
  const [modalShow, setModalShow] = useState(false);


  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined ) window.location.replace("/")
 

  const [showButton, setShowButton] = useState(false);
  setTimeout(() => {
    setShowButton(true);
  }, 10000);

  return (
    <React.Fragment>
      <div className="Loan_steep1_acounts">

        <Offstikesmodal/>

        <>
          <Offcanvasmodal />
        </>

        {/* NAV */}

        <div className="Loans_step_1_card">
          <div className="loans_steep_1_card_title">
            <h6>Home Loans </h6>
            <h6 className="loans_steep_1_nav_ppp"> Personal Loans</h6>
            <h6 className="loans_steep_1_nav_p"> Car Loans</h6>
            <h6 className="loans_steep_1_nav_pp"> Education Loans </h6>
            <h6 className="loans_steep_1_nav_p">Loans Promotions</h6>
          </div>
          <hr />

          <h3>Let's get started.</h3>
          <h6>New Address</h6>

          <div className="loansteep1_cards">
            <div className="steep_cards_1">
              <h3 className="steep_cards_1_h3">Property Price</h3>
              <p>I have a price in mind for my future home</p>
            </div>

            <div className="steep_cards_1">
              <h3 className="steep_cards_1_h3">Monthly Instalment</h3>
              <p> I know how much I am prepared to pay monthly</p>
            </div>

            <div className="steep_cards_1">
              <h3 className="steep_cards_1_h3">Down Payment</h3>
              <p> I have set aside a downpayment for my purchase</p>
            </div>

            <div className="steep_cards_1">
              <h3 className="steep_cards_1_h3">I’m still exploring</h3>
              <p>I want to see my maximum loan amount</p>
            </div>
          </div>

          <div className="Loans_steep_but">
            <Link to={`${window.location.origin}/${Login.id}/Loansstep2`}>
              {showButton ? (
                <button className="Loans_button">Next</button>
              ) : undefined}
            </Link>
          </div>
        </div>

        {/* MOODALL */}

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Terms and Conditions"}
          body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
        />
        {/* Bottom  text */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Loansstep1;
