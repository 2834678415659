import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import logowhite from "../../Images/logo-white 1.png";
import usersnove from "../../Images/user.png";

import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import axios from "axios";
import MyVerticallyCenteredModal from "../modal";

const Offstikesmodal = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const params = useParams();
  const [show, setShow] = useState(false);
  const [User, setUser] = useState({});
  const [Transfer, setTransfer] = useState(0);
  // const [PayFrom, setPayFrom] = useState("Select");
  const [PayTo, setPayTo] = useState("Select");

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  const TransferFunc = (e) => {
    setTransfer(e.target.value);
    if (e.target.value > User.banks?.balance) {
      setTransfer(User.banks?.balance);
      // console.log(Transfer);
    }
  };

  const RandomAccount = () => {
    const random = Math.floor(Math.random() * 99999999);

    if (random >= 10000000 && random <= 99999999) {
      return random;
    } else {
      RandomAccount();
    }
  };

  const PaymentFunc = () => {
    const isAgree = window.confirm(`Are your sure? ${Transfer}`);

    if (isAgree) {
      axios
        .patch(`http://localhost:9000/users/${params.userId}`, {
          banks: {
            ...Login.banks,
            balance: Number(User.banks?.balance) - Number(Transfer),
            transaction: [
              {
                title: PayTo,
                paid: Transfer,
                account: RandomAccount(),
              },
              ...Login?.banks?.transaction,
            ],
          },
        })
        .then(() => {
          axios
            .get(`http://localhost:9000/users/${params.userId}`)
            .then((data) => {
              localStorage.setItem("login", JSON.stringify(data.data));
              window.location.reload();
            });
        });
    }
  };

  useEffect(() => {
    Login ?? window.location.replace("/");
    if (Login) {
      setUser(Login);
    }
  }, []);

  return (
    <React.Fragment>
      <div onClick={handleShow} className="stike_modall">
        <i id="stike_modal_img" className="bx bxs-chevrons-left"></i>
      </div>

      <Offcanvas show={show} onHide={handleClose} className="ectem_coloor">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="ecten_title"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="acounts_nav_ectem">
            <div className="acouts_nav_novus">
              <img className="acounts_nav_logo" src={logowhite} alt="error" />
              <div className="owerview_users">
                <img
                  className="owerview_img"
                  src={User.avatar === "" ? usersnove : User.avatar}
                  alt="error"
                />
                <div className="owerview_users_text">
                  <h6 className="owerview_users_text_title">
                    {User.last_name}
                  </h6>
                  <p className="owerview_p" onClick={HandleLogout}>
                    Logout
                  </p>
                </div>
              </div>

              <div className="novus_nav_text">
                <Link
                  className="link_loans_tu"
                  to={`${window.location.origin}/${Login.id}/Overview`}
                >
                  <p className="novus_nav_p">Overview</p>
                </Link>

                <div className="novus_nav_dr">
                  {" "}
                  <Dropdown>
                    <Link className="link_loans_tu" to={""}>
                      <p className="novus_nav_drow">
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                          Accounts
                        </Dropdown.Toggle>
                      </p>
                    </Link>
                    <Dropdown.Menu>
                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${Login.id}/Homeacounts`}
                      >
                        <Dropdown.Item href="#/action-1">
                          Accounts
                        </Dropdown.Item>
                      </Link>
                      <Dropdown.Divider />

                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${Login.id}/Extemalacounts`}
                      >
                        <Dropdown.Item href="#/action-2">
                          Add External Account
                        </Dropdown.Item>
                      </Link>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => setModalShow(true)}
                      >
                        Create New Account (TM)
                      </Dropdown.Item>
                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${Login.id}/Unlinkacounts`}
                      >
                        <Dropdown.Item href="#/action-4">
                          Unlink Account
                        </Dropdown.Item>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Link
                  className="link_loans_tu"
                  to={`${window.location.origin}/${Login.id}/Personaldetails`}
                >
                  <p className="novus_nav_p"> Payments</p>
                </Link>

                <Link
                  className="link_loans_tu"
                  to={`${window.location.origin}/${Login.id}/Loans`}
                >
                  <p className="novus_nav_p"> Loans</p>
                </Link>

                <p className="novus_nav_p">Investments</p>
                <p className="novus_nav_p"> Contact Us</p>
              </div>
            </div>

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              title={"Terms and Conditions"}
              body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default Offstikesmodal;
