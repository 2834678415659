import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";

import { Link, useParams } from "react-router-dom";
import context from "react-bootstrap/esm/AccordionContext";
import Assets1 from "../Paymentsassets/Assets1";
import Assets2 from "../Paymentsassets/Assets2";
import Assets3 from "../Paymentsassets/Assets3";
import Assets4 from "../Paymentsassets/Assets4";

const Personaldetails5idcards7 = () => {
  const { userRegisterObject } = useContext(context);

  const Api = "http://localhost:9000/financia_banks";
  // const [userCountry, setUsercountry] = useState("Select");
  const params = useParams();
  const [carta, setCarta] = useState([]);
  const [setSearchQuery] = useState("");
  const [setFilteredCards] = useState(carta);
  const [ProductData, setProductData] = useState({});
  const [Next] = useState(false);
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = carta.filter((card) =>
      card.title.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCards(query.trim() === "" ? carta : filtered);
  };

  const [eneble, setEneble] = useState("");
  const [cheking, setCheking] = useState("");
  const [savingg, setSaving] = useState("");

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  const HandleNext = () => {
    // setNext(true);
    if (eneble !== "" && cheking !== "" && savingg !== "" && !Next) {
      userRegisterObject.eneble = eneble;
      userRegisterObject.cheking = cheking;
      userRegisterObject.saving = savingg;

      return (
        <Link
          className="personal_btn_button financial_button"
          to={`${window.location.origin}/${Login.id}/singleproduct/step2/${ProductData.id}`}
        >
          Next
        </Link>
      );
    } else {
      return (
        <button className="financial_button_btn">
          Next <i className="bx bx-right-arrow-alt" id="financial_i"></i>{" "}
        </button>
      );
    }
  };

  useEffect(() => {
    axios
      .get(Api)
      .then((res) => setCarta(res.data))
      .catch((err) => console.log(err));
    axios.get(`${Api}/${params.productId}`).then((response) => {
      const data = response.data;
      setProductData(data);
    });
  }, []);

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">5</button> Financial
            Position
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <Assets1 />
            <Assets2 />
            <Assets3 />
            <Assets4 />

            {/* <div className="financial_buttons">
              <Link
                className="financial_link"
                to={`${window.location.origin}/${Login.id}/singleproduct/step6/1`}
              >
                <button className="financial_previous">
                  <i className="bx bx-left-arrow-alt" id="financial_i"></i>
                  Previous{" "}
                </button>
              </Link>
              <div>
                {" "}
                <Link
                  className="personal_btn_button financial_button"
                  to={`${window.location.origin}/${Login.id}/singleproduct/step8/${ProductData.id}`}
                >
                  Next
                </Link>
              </div>
            </div> */}

            {/* <div className="personal_buttons">
              <p className="personal_red">Fields marked with * are mandatory</p>

              <div className="personal_btn">
                <Link to={"/singleproduct/step5/1"}>
                  <button disabled className="finanse_coutine_btn">
                    Back
                  </button>
                </Link>
                <Link to={""}>
                  <button className="signing_button_payments">Save</button>
                </Link>

                <Link to={""}>
                  <button disabled className="finanse_coutine_btn">
                    Continue
                  </button>
                </Link>
              </div>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails5idcards7;
