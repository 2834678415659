import React, { useEffect } from "react";
import "./Overview.css";
import { Link, useParams } from "react-router-dom";

import logowhite from "../../Images/logo-white 1.png";
import usersnove from "../../Images/user.png";

import tranfericons from "../../Images/icons-01.svg";
import summaryicons from "../../Images/icons-02.svg";
import summaryicons1 from "../../Images/icons-03.svg";
import linkssicon from "../../Images/icons-04.svg";
import linkssicons1 from "../../Images/icons-05.svg";
import linkssicons2 from "../../Images/icons-06.svg";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import SplitButton from "react-bootstrap/SplitButton";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import Footer from "../../components/Footer/Footer.jsx";
import axios from "axios";

// import stikemodalimg from "../../Images/stikke_modal.png";
import Offstikesmodal from "../../components/Offcanvasmodal/Offstikesmodal";

const Overview = (props) => {
  const [setModalShow] = React.useState(false);
  const params = useParams();
  const [show, setShow] = useState(false);
  const [User, setUser] = useState({});
  const [Transfer, setTransfer] = useState(0);
  const [PayFrom, setPayFrom] = useState("Select");
  const [PayTo, setPayTo] = useState("Select");
  const [FilterAccountNumber, setFilterAccountNumber] = useState("Filter");
  const [FilterDate, setFilterDate] = useState("Filter");
  // const [TransactionFeed, setTransactionFeed] = useState([]);

  const Login = JSON.parse(localStorage.getItem("login"));

  const Time = new Date();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  const TransferFunc = (e) => {
    setTransfer(e.target.value);
    if (e.target.value > User.banks?.balance) {
      setTransfer(User.banks?.balance);
      // console.log(Transfer);
    }
  };

  const RandomAccount = () => {
    const random = Math.floor(Math.random() * 99999999);

    if (random >= 10000000 && random <= 99999999) {
      return random;
    } else {
      RandomAccount();
    }
  };

  const PaymentFunc = () => {
    const isAgree = window.confirm(`Are your sure? ${Transfer}`);

    if (isAgree) {
      axios
        .patch(`http://localhost:9000/users/${params.userId}`, {
          banks: {
            ...Login.banks,
            balance: Number(User.banks?.balance) - Number(Transfer),
            transaction: [
              {
                title: PayTo,
                paid: Transfer,
                account: RandomAccount(),
                date: `${Time.getDate()}:${
                  Time.getMonth() + 1
                }:${Time.getFullYear()}`,
              },
              ...Login?.banks?.transaction,
            ],
          },
        })
        .then(() => {
          axios
            .get(`http://localhost:9000/users/${params.userId}`)
            .then((data) => {
              localStorage.setItem("login", JSON.stringify(data.data));
              window.location.reload();
            });
        });
    }
  };

  useEffect(() => {
    Login ?? window.location.replace("/");
    if (Login) {
      setUser(Login);
    }

    // setTransactionFeed([...User?.banks?.transaction]);
  }, []);

  const UserId = JSON.parse(localStorage.getItem("login"));

  if (UserId === null || UserId === undefined) window.location.replace("/");
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  // const day = currentDate.getDate().toString().padStart(2, "0");

  return (
    <>
      <div className="owerview">
        <div className="owerview_div_ota">
          {/* <div className="stike_modall">
            <i id="stike_modal_img" className="bx bxs-chevrons-left"></i>
          </div> */}

          <Offstikesmodal />

          <div className="owerview_card_navcha">
            <Button
              className="exten_ower_ower"
              variant="success"
              onClick={handleShow}
            >
              <img src={logowhite} alt="error" />
            </Button>

            <Offcanvas
              show={show}
              onHide={handleClose}
              className="ectem_coloor"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className="ecten_title"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="acounts_nav_ectem">
                  <div className="acouts_nav_novus">
                    <img
                      className="acounts_nav_logo"
                      src={logowhite}
                      alt="error"
                    />
                    <div className="owerview_users">
                      <img
                        className="owerview_img"
                        src={User.avatar === "" ? usersnove : User.avatar}
                        alt="error"
                      />
                      <div className="owerview_users_text">
                        <h5 className="owerview_users_text_title">
                          {User.last_name}
                        </h5>
                        <p className="owerview_p" onClick={HandleLogout}>
                          Logout
                        </p>
                      </div>
                    </div>

                    <div className="novus_nav_text">
                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${UserId.id}/Overview/`}
                      >
                        <p className="novus_nav_p">Overview</p>
                      </Link>

                      <div className="novus_nav_dr">
                        {" "}
                        <Dropdown>
                          <Link className="link_loans_tu" to={""}>
                            <p className="novus_nav_drow">
                              <Dropdown.Toggle variant="" id="dropdown-basic">
                                Accounts
                              </Dropdown.Toggle>
                            </p>
                          </Link>
                          <Dropdown.Menu>
                            <Link
                              className="link_loans_tu"
                              to={`${window.location.origin}/${UserId.id}/Homeacounts`}
                            >
                              <Dropdown.Item href="#/action-1">
                                Accounts
                              </Dropdown.Item>
                            </Link>
                            <Dropdown.Divider />

                            <Link
                              className="link_loans_tu"
                              to={`${window.location.origin}/${UserId.id}/Extemalacounts`}
                            >
                              <Dropdown.Item href="#/action-2">
                                Add External Account
                              </Dropdown.Item>
                            </Link>
                            <Dropdown.Item
                              href="#/action-3"
                              onClick={() => setModalShow(true)}
                            >
                              Create New Account (TM)
                            </Dropdown.Item>
                            <Link
                              className="link_loans_tu"
                              to={`${window.location.origin}/${UserId.id}/Unlinkacounts`}
                            >
                              <Dropdown.Item href="#/action-4">
                                Unlink Account
                              </Dropdown.Item>
                            </Link>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${UserId.id}/Personaldetails`}
                      >
                        <p className="novus_nav_p"> Payments</p>
                      </Link>

                      <Link
                        className="link_loans_tu"
                        to={`${window.location.origin}/${UserId.id}/Loans`}
                      >
                        <p className="novus_nav_p"> Loans</p>
                      </Link>

                      <p className="novus_nav_p">Investments</p>
                      <p className="novus_nav_p"> Contact Us</p>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            <div className="owerview_navcha_userimg">
              <div className="avatar-imageholder">
                <img
                  className="owerview_navcha_imge"
                  src={User.avatar === "" ? usersnove : User.avatar}
                  alt="error"
                />
              </div>
              <h5 className="owerview_users_text_title">{User.last_name}</h5>
              <p className="owerview_p" onClick={HandleLogout}>
                Logout
              </p>
            </div>
          </div>

          <div className="owerview_card">
            <div className="balance">
              <div className="available">
                <p>Available Balance</p>
                <h1 className="available_h1"> £ {User?.banks?.balance}</h1>
              </div>

              <div className="transfer">
                <div className="transfer_title">
                  <div className="transfer_icons">
                    <img
                      className="transfer_icons_s"
                      src={tranfericons}
                      alt=""
                    />
                    <h6>Quick Transfer</h6>
                  </div>
                  <p className="transfer_red">view all</p>
                </div>

                <div className="transfer_from">
                  <div className="pay_from">
                    <p>Pay From </p>

                    {["Secondary"].map((variant) => {
                      return (
                        <DropdownButton
                          as={ButtonGroup}
                          key={variant}
                          id={`dropdown-variants-${variant}`}
                          variant={variant.toLowerCase()}
                          title={PayFrom}
                        >
                          {["Click", "Payme"].map((value, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={(e) => setPayFrom(e.target.text)}
                                >
                                  {value}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                              </React.Fragment>
                            );
                          })}
                        </DropdownButton>
                      );
                    })}
                  </div>

                  <div className="pay_to">
                    <p>Pay To </p>
                    <div>
                      {["Secondary"].map((variant) => {
                        return (
                          <DropdownButton
                            as={ButtonGroup}
                            key={variant}
                            id={`dropdown-variants-${variant}`}
                            variant={variant.toLowerCase()}
                            title={PayTo}
                          >
                            {[
                              "Internet",
                              "Mobile",
                              "Supermarkets",
                              "Max-Way",
                              "Evos",

                              "Bellissimo",
                            ].map((value, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Dropdown.Item
                                    eventKey="1"
                                    onClick={(e) => setPayTo(e.target.text)}
                                  >
                                    {value}
                                  </Dropdown.Item>
                                </React.Fragment>
                              );
                            })}
                          </DropdownButton>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="treansfer_but_dic" >
                  <input
                    className="transfer_input"
                    type="number"
                    onInput={TransferFunc}
                    value={Transfer}
                    disabled={
                      PayFrom === "Select" || PayTo === "Select" ? true : false
                    }
                  />

                  <textarea
                    className="textaria"
                    placeholder="Remarks"
                    cols="30"
                    rows="10"
                    disabled={
                      PayFrom === "Select" || PayTo === "Select" ? true : false
                    }
                  ></textarea>

                  <button
                    disabled={
                      PayFrom === "Select" || PayTo === "Select" ? true : false
                    }
                    className="transfer_button"
                    onClick={PaymentFunc}
                  >
                    Make a Payment
                  </button>
                </div>

                {PayFrom === "Select" || PayTo === "Select" ? (
                  <p className="transfer_red">
                    Fields marked with * are mandatory
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="summary">
              <div className="acaund">
                <div className="transfer_icons">
                  <img className="transfer_icons_s" src={summaryicons} alt="" />
                  <h6>Account Summary</h6>
                </div>

                <div className="acaund_title">
                  <p className="perrsf">Accounts </p>
                </div>

                <hr />
                <div className="acaund_text">
                  <div className="acaund_text-top">
                    <h6>Account Number </h6>
                    <h6>Account Balance</h6>
                  </div>
                  {User?.banks?.transaction?.map((value, index) => {
                    return (
                      <div className="acaund_text-row" key={index}>
                        <div className="account_number">{value.account}</div>
                        <div className="account_balance">
                          £ {value.paid || value.my_downpayment}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="summary_fedd">
                <div className="summary_fedd_icons">
                  <div className="summary_icons_1">
                    <img
                      className="transfer_icons_s"
                      src={summaryicons1}
                      alt=""
                    />
                    <h6>Transaction Feed</h6>
                  </div>

                  <div className="summary_icons_1">
                    <input
                      className="summary_input"
                      type="date"
                      name="1"
                      id="1"
                    />
                  </div>
                </div>

                <div className="summary_fedd_title">
                  <p className="summary_fedd_title_p">Account number</p>

                  <div>
                    {["Secondary"].map((variant) => (
                      <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={FilterAccountNumber}
                      >
                        {User?.banks?.transaction?.map((value, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Dropdown.Item
                                eventKey={index}
                                onClick={(e) =>
                                  setFilterAccountNumber(e.target.textContent)
                                }
                              >
                                {value?.account}
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </DropdownButton>
                    ))}
                  </div>

                  <div>
                    {["Secondary"].map((variant) => (
                      <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={FilterDate}
                      >
                        {User?.banks?.transaction?.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Dropdown.Item
                                eventKey={i}
                                onClick={(e) =>
                                  setFilterDate(e.target.textContent)
                                }
                              >
                                {item?.date}
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </DropdownButton>
                    ))}
                  </div>
                </div>

                <div className="fedd_text">
                  <p>Total Amount Spent £1,491.00 in 2023</p>

                  {Login?.banks?.transaction?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="fedd_text_1">
                          <p> {item?.title} </p>
                          <p> £{item?.paid || item.my_downpayment}</p>
                        </div>
                        <hr />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="owerview_linkss">
              <div className="linkss_icons">
                <div className="linkss_iconss">
                  <img className="linkss_icons_s" src={linkssicon} alt="" />
                  <h6>Quick Links</h6>
                </div>

                <p className="linkss_pp">
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p">Account Statement</p>
                  </Link>
                  <Link
                    className="link_loans_tu"
                    to={`/${UserId.id}/Unlinkacounts`}
                  >
                    <p className="linkss_pp_p"> Block Debit Card</p>
                  </Link>
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p"> Download TDS</p>
                  </Link>
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p"> Certificate</p>
                  </Link>
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p"> Initiate NEFT/RTGS</p>
                  </Link>
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p"> Apply Car Loan</p>
                  </Link>
                  <Link className="link_loans_tu" to={`/${UserId.id}/Loans`}>
                    <p className="linkss_pp_p"> Apply Home Loan</p>
                  </Link>
                  <Link className="link_loans_tu" to={"/404"}>
                    <p className="linkss_pp_p"> Play Your Bills</p>
                  </Link>
                </p>
                <p className="more">more</p>
              </div>

              <div className="manager">
                <div className="linkss_iconss">
                  <img className="linkss_icons_s" src={linkssicons1} alt="" />
                  <h6>Relationship Manager</h6>
                </div>
                <p>
                  John Doe <br /> +91 203252225
                </p>
                <p>johndoe@novusbank.com</p>
              </div>

              <div className=" insurance">
                <div className="linkss_iconss">
                  <img className="linkss_icons_s" src={linkssicons2} alt="" />
                  <h6>My Insurance</h6>
                </div>
                <p className="acaund_p">Term Life Insurance</p>
                <p> £ 1000 @12/month</p>
                <p className="more">more</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Overview;
