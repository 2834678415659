import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import { Link } from "react-router-dom";
import { Context } from "../../../Context";

const Personaldetails3 = () => {
  const { userRegisterObject } = useContext(Context);

  const UserData = JSON.parse(localStorage.getItem("login"))

  const [groos, setGroos] = useState("");
  const [bonus, setBonus] = useState("");
  const [salar, setSalar] = useState("");
  const [employer, setEmployer] = useState("Select");
  const [industry, setIndustry] = useState("Select");
  const [position, setPosition] = useState("Select");
  const [months, setMonths] = useState("")
  const [ years, setYears ] = useState("")

  const UserSalarFunc = (e = Element) => {
    setSalar(e.target.value);
    userRegisterObject.personal.personalbanks.sector =
      e.target.dataset.roleNumber;
  };

  const MandatoryInfoFunc = () => {
    if (
      groos === "" ||
      bonus === "" ||
      salar === "" ||
      employer === "" ||
      industry === "" ||
      position === "" ||
      years === "" ||
      months === "" 
    ) {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  const HandleNext = () => {
    if (
      groos !== "" &&
      bonus !== "" &&
      salar !== "" &&
      employer !== "" &&
      industry !== "" &&
      position !== "" &&
      years !== "" &&
      months !== ""
    ) {
      userRegisterObject.personal.personalbanks.bonus_overtime = bonus;
      userRegisterObject.personal.personalbanks.bonus_income = groos;
      userRegisterObject.personal.personalbanks.sector = salar;
      userRegisterObject.personal.personalbanks.employer = employer;
      userRegisterObject.personal.personalbanks.industry = industry;
      userRegisterObject.personal.personalbanks.position = position;
       userRegisterObject.personal.personalbanks.years = years;
       userRegisterObject.personal.personalbanks.mounth = months;
      return (
        <Link
          className="signing_button_payments lin_personal_decoration"
          to={`${window.location.origin}/${Login.id}/Loandetails`}
        >
          Continue
        </Link>
      );
    } else {
      return <button className=" signing_button_payments">Continue</button>;
    }
  };

  const [setPersonaluser] = useState([]);
  const [User, setUser] = useState({});


  const SaveFunc = () => {
    const UpdatesKeyValues = {
      ...UserData?.personal?.personalbanks,
      bonus_overtime: bonus,
      bonus_income: groos,
      sector: salar,
      employer: employer,
      industry: industry,
      position: position,
      years: years,
      mounth: months,
    };

    localStorage.setItem(
      "login",
      JSON.stringify({
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
    );

    axios
      .patch(`http://localhost:9000/users/${UserData.id}`, {
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
      .then(() => alert(`Ok`))
      .catch((error) => {
        alert("error");
        console.log(error);
      });
  }

    useEffect(() => {
      
      axios
        .get(`http://localhost:9000/users`)
      .then((res) => setPersonaluser(res.data))
      .catch((err) => console.log(err));
    setUser(UserData)
    }, [])


  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users`)
      .then((res) => setPersonaluser(res.data))
      .catch((err) => console.log(err));
    setUser(UserData);
  }, []); 

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">3</button> Employment
            Details
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="personal_applicat_steep_2">
              <div className="employment_radio">
                <div className="emloyment_radio_input">
                  <input
                    type="radio"
                    name="role"
                    id="input"
                    value={"Salaried"}
                    data-role-number={1}
                    onChange={(e) => UserSalarFunc(e)}
                  />
                  <p className="emloyment_input_p">Salaried</p>
                </div>
                <div className="emloyment_radio_input">
                  <input
                    type="radio"
                    name="role"
                    id="input"
                    value={"If self-employed"}
                    data-role-number={2}
                    onChange={(e) => UserSalarFunc(e)}
                  />
                  <p className="emloyment_input_p">If self-employed</p>
                </div>
                <div className="emloyment_radio_input">
                  <input
                    type="radio"
                    name="role"
                    id="input"
                    value={"If Retired"}
                    data-role-number={3}
                    onChange={(e) => UserSalarFunc(e)}
                  />
                  <p className="emloyment_input_p">If Retired</p>
                </div>
                <div className="emloyment_radio_input">
                  <input
                    type="radio"
                    name="role"
                    id="input"
                    value={"If Others"}
                    data-role-number={4}
                    onChange={(e) => UserSalarFunc(e)}
                  />
                  <p className="emloyment_input_p">If Others</p>
                </div>
              </div>

              <div className="applicant">
                <div className="aplicant_role">
                  <p>Employer name </p>
                  <>
                    {["Secondary"].map((variant) => (
                      <SplitButton
                        className="personal_dv_2"
                        key={variant}
                        id={`dropdown-split-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={employer}
                        onChange={(e) => setEmployer(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setEmployer(e.target.outerText)}
                          eventKey="1"
                        >
                          Barclays Bank{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setEmployer(e.target.outerText)}
                          eventKey="2"
                        >
                          Grape Bank
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setEmployer(e.target.outerText)}
                          eventKey="3"
                        >
                          People's Bank{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setEmployer(e.target.outerText)}
                          eventKey="4"
                        >
                          Suncorp Bank
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={(e) => setEmployer(e.target.outerText)}
                          eventKey="5"
                        >
                          Other
                        </Dropdown.Item>
                      </SplitButton>
                    ))}
                  </>

                  <p>Industry </p>
                  <>
                    {["Secondary"].map((variant) => (
                      <SplitButton
                        className="personal_dv_2"
                        key={variant}
                        id={`dropdown-split-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setIndustry(e.target.outerText)}
                          eventKey="1"
                        >
                          Financial
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setIndustry(e.target.outerText)}
                          eventKey="2"
                        >
                          Other
                        </Dropdown.Item>
                      </SplitButton>
                    ))}
                  </>

                  <p>Email </p>

                  <input
                    className="input_personal_sign"
                    type="email"
                    placeholder="david.wisner@gmail.com"
                    value={User?.email}
                  />

                  <p>Gross annual income </p>

                  <input
                    className="input_personal_sign"
                    type="number"
                    placeholder="£ 2000"
                    value={groos}
                    onChange={(e) => setGroos(e.target.value)}
                  />
                </div>

                <div className="co_aplicant">
                  <div className="title_select_butonn">
                    <p>Current position </p>
                    <>
                      {["Secondary"].map((variant) => (
                        <SplitButton
                          className="personal_dv_2"
                          key={variant}
                          id={`dropdown-split-variants-${variant}`}
                          variant={variant.toLowerCase()}
                          title={position}
                          onChange={(e) => setPosition(e.target.value)}
                        >
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="1"
                          >
                            Manager
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="2"
                          >
                            Bugalter
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="3"
                          >
                            {" "}
                            Police
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="4"
                          >
                            Doctor
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="5"
                          >
                            Programist
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="6"
                          >
                            Teacher
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={(e) => setPosition(e.target.outerText)}
                            eventKey="7"
                          >
                            Other
                          </Dropdown.Item>
                        </SplitButton>
                      ))}
                    </>
                  </div>

                  <div className="empolment_ownership">
                    <p>Duration with current employer</p>
                    <div className="employment_onwip">
                      <div className="empoyment_input">
                        <input
                          className="input_empoyment_postcode"
                          type="text"
                          placeholder=" 20"
                          value={years}
                          onChange={(e) => setYears(e.target.value) }
                        />
                        <h6 className="empolment_ownership_p">Years</h6>
                      </div>

                      <div className="empoyment_input">
                        <input
                          className="input_empoyment_postcode"
                          type="text"
                          placeholder=" 24"
                          value={months}
                          onChange={(e) => setMonths(e.target.value) }
                        />
                        <h6 className="empolment_ownership_p">Months</h6>
                      </div>
                    </div>
                  </div>

                  <p className="work_number">Work phone number </p>

                  <input
                    className="input_personal_sign"
                    type="number"
                    placeholder="+44 3 6651 289"
                    value={User?.phone_number}
                  />

                  <p>Bonus/overtime/Other benefits </p>

                  <input
                    className="input_personal_sign"
                    type="number"
                    placeholder="£ 2000"
                    value={bonus}
                    onChange={(e) => setBonus(e.target.value)}
                  />
                </div>
              </div>
              <div className="personal_buttons">
                {MandatoryInfoFunc()}
                <div className="personal_btn">
                  <Link
                    to={`${window.location.origin}/${Login.id}/Contactdetails`}
                  >
                    <button className="payments_back_b">Back</button>
                  </Link>
                  <Link to={""}>
                    <button className="signing_button_payments" onClick={() => SaveFunc() } >Save</button>
                  </Link>

                  {HandleNext()}

                  {/* <Link
                    to={`${window.location.origin}/${Login.id}/Loandetails`}
                  >
                    <button className="signing_button_payments">
                      Continue
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails3;
