import React, { useContext, useEffect } from "react";
import "./Loansstep3.css";

import { Link, useParams } from "react-router-dom";

import MyVerticallyCenteredModal from "../modal";

import { useState } from "react";

import steep3 from "../../Images/steep3.png";
import steep3sv from "../Img/steep3.svg";

import Footer from "../Footer/Footer.jsx";
import Offcanvasmodal from "../Offcanvasmodal/Offcanvasmodal";
import axios from "axios";
import { Context } from "../../Context";
import Offstikesmodal from "../Offcanvasmodal/Offstikesmodal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const Loansstep3 = () => {
  const [modalShow, setModalShow] = useState(false);

  const [showButton, setShowButton] = useState(false);
  setTimeout(() => {
    setShowButton(true);
  }, 10000);

  // const [loans, setloans] = useState([])

  // useEffect(() => {
  //   axios.get("http://localhost:9000/users").then(res => setloans(res.data)).catch(err => console.log(err))
  // } )

  const [PayFrom, setPayFrom] = useState("Select");
  const [PayTo, setPayTo] = useState("Select");
  const [Transfer, setTransfer] = useState(0);

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  const params = useParams();
  const [User, setUser] = useState({});


  const RandomAccount = () => {
    const random = Math.floor(Math.random() * 99999999);

    if (random >= 10000000 && random <= 99999999) {
      return random;
    } else {
      RandomAccount();
    }
  };

  const PaymentFunc = () => {
    const isAgree = window.confirm(`Are your sure? ${Transfer}`);

    if (isAgree) {
      axios
        .patch(`http://localhost:9000/users/${params.userId}`, {
          banks: {
            ...Login.banks,
            balance: Number(User.banks?.balance) - Transfer,
            transaction: [
              {
                title: PayTo,
                my_downpayment: Transfer,
                account: RandomAccount(),
              },
              ...Login?.banks?.transaction,
            ],
          },
        })
        .then(() => {
          axios
            .get(`http://localhost:9000/users/${params.userId}`)
            .then((data) => {
              localStorage.setItem("login", JSON.stringify(data.data));
              window.location.reload();
            });
        });
    }
  };

  useEffect(() => {
    Login ?? window.location.replace("/");
    if (Login) {
      setUser(Login);
    }
  }, []);




 const TransferFunc = (e) => {
   setTransfer(e.target.value);
   if (e.target.value > User.banks?.balance) {
     setTransfer(User.banks?.balance);
     // console.log(Transfer);
   }
 };



  
  return (
    <React.Fragment>
      <div className="Loan_steep1_acounts">
        <Offstikesmodal />

        <>
          <Offcanvasmodal />
        </>

        {/* NAV */}

        <div className="Loans_step_3_card">
          <div className="loans_steep_1_card_title">
            <h6>Home Loans </h6>
            <h6 className="loans_steep_1_nav_ppp"> Personal Loans</h6>
            <h6 className="loans_steep_2_nav_p"> Car Loans</h6>
            <h6 className="loans_steep_1_nav_pp"> Education Loans </h6>
            <h6 className="loans_steep_2_nav_p">Loans Promotions</h6>
          </div>
          <hr />

          <div className="Loans_steep_3_carder">
            <div className="steep_card_1">
              <h5 className="steep_card_1_h55">My downpayment ?</h5>

              {/* <img className="steep_3_imges" src={steep3} alt="" /> */}
              <div className="transfer_from">
                <div className="pay_from">
                  <p>Pay From </p>

                  {["Secondary"].map((variant) => {
                    return (
                      <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={PayFrom}
                      >
                        {["Click", "Payme"].map((value, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Dropdown.Item
                                eventKey="1"
                                onClick={(e) => setPayFrom(e.target.text)}
                              >
                                {value}
                              </Dropdown.Item>
                              <Dropdown.Divider />
                            </React.Fragment>
                          );
                        })}
                      </DropdownButton>
                    );
                  })}
                </div>

                <div className="pay_to">
                  <p>Pay To </p>
                  <div>
                    {["Secondary"].map((variant) => {
                      return (
                        <DropdownButton
                          as={ButtonGroup}
                          key={variant}
                          id={`dropdown-variants-${variant}`}
                          variant={variant.toLowerCase()}
                          title={PayTo}
                        >
                          {["My home loan"].map((value, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={(e) => setPayTo(e.target.text)}
                                >
                                  {value}
                                </Dropdown.Item>
                              </React.Fragment>
                            );
                          })}
                        </DropdownButton>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="loans_step_3_inputs" >
                <input
                  className="transfer_loans_input"
                  type="number"
                  onInput={TransferFunc}
                  value={Transfer}
                  disabled={
                    PayFrom === "Select" || PayTo === "Select" ? true : false
                  }
                />

                <button
                  disabled={
                    PayFrom === "Select" || PayTo === "Select" ? true : false
                  }
                  className="transfer_loans_button"
                  onClick={PaymentFunc}
                >
                  Make a Payment
                </button>
              </div>
              {PayFrom === "Select" || PayTo === "Select" ? (
                <p className="transfer_red">
                  Fields marked with * are mandatory
                </p>
              ) : (
                ""
              )}
              <hr />
              <div className="steep_card_1_dom">
                <div>
                  <img className="steep_3_svg" src={steep3sv} alt="" />
                </div>
                <div className="steep_card_1_dom_2">
                  <h6 className="b_dom_2_h6">Could I get a higher loan?</h6>
                  <h6 className="b_dom_2_h6">
                    {" "}
                    <Link to={"/404"}>
                      {" "}
                      <span className="steep_card_1_dom_2_p">Get tips</span>
                    </Link>{" "}
                    on how to improve your loan eligibility
                  </h6>
                </div>
              </div>
            </div>

            <div className="steep_card_2">
              <h5 className="steep_card_1_h55">How much am I looking at?</h5>
              <div className="steep_card_2_ratio">
                <h1>£ {Number(User?.banks?.my_balance?.home_price)} </h1>
                <p> My maximum home loan </p>
                <p>
                  This is assessed based on your Mortgage Servicing Ratio (MSR)
                  Limit
                </p>
              </div>

              <div className="steep_card_2_cash">
                {User?.banks?.transaction?.map((value, index) => {
                  return (
                    <div className="steep_3_price" key={index}>
                      <h1> £ {value?.my_downpayment || "0"} </h1>
                    </div>
                  );
                })}
              </div>
              <p> My downpayment</p>
              <p> CPF and/or Cash $2,229,167</p>
              <p> Cash $156,157</p>
            </div>
          </div>

          <div className="steep_card_3">
            <h5 className="steep_card_1_h55">My home loan summary</h5>
            <div className="steep_card_3_carder">
              <div className="steep_card_3_card_1">
                <p>Your maximum home loan</p>
                <h1 className="loans_h1">
                  £ {Number(User?.banks?.my_balance?.home_price)}{" "}
                </h1>
                <p>
                  {" "}
                  This is assessed based on your Mortgage <br /> Servicing Ratio
                  (MSR) Limit
                </p>
              </div>

              <div className="steep_card_3_card_2">
                <p>Monthly Instalment</p>
                <h1 className="loans_h1">
                  {" "}
                  £ {Number(User?.banks?.my_balance?.instalment)}{" "}
                </h1>
                <p>
                  Based on indicative prevailing interest rates of 3.5% to{" "}
                  {User?.banks?.my_balance?.loan_stavka} %
                </p>
              </div>

              <div className="steep_card_3_card_3">
                <p>Loan period</p>
                <h1 className="loans_h1">
                  {" "}
                  {User?.banks?.my_balance?.loan_years ?? "0"} Years
                </h1>
                <p>
                  Enter a loan period between {User?.personal?.date?.mounth}
                  to {User?.banks?.my_balance?.loan_years ?? "0"} years
                </p>
              </div>
            </div>
          </div>

          <div className="Loans_steep_3_but">
            <Link to={`${window.location.origin}/${Login.id}/Loans`}>
              {showButton ? (
                <button className="Loans_steep_3_button">
                  Re-plan home loan
                </button>
              ) : undefined}
            </Link>

            <Link to={`${window.location.origin}/${Login.id}/Loansstep4`}>
              {showButton ? (
                <button className="Loans_steep_3_button">Continue</button>
              ) : undefined}
            </Link>
          </div>
        </div>

        {/* MOODALL */}

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Terms and Conditions"}
          body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
        />
        {/* Bottom  text */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Loansstep3;
