import React, { useState }  from 'react'
import Personalcart from './Personalcart/Personalcart'


import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

const My_Future_Home = () => {

     const [show, setShow] = useState(false);

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <>
        <Button variant="success" onClick={handleShow}>
          My-Future
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>My-Future</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='my_futuree' >
            <Personalcart />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </React.Fragment>
  );
}

export default My_Future_Home
