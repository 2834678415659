import React, { useEffect, useState } from "react";

import "../FinancialpositionId_cards_8/FinancialpositionIdcards8.css"

import Accordion from "react-bootstrap/Accordion";

import assets5 from "./Img/assets5.png";

import axios from "axios";
import { Link, useParams } from "react-router-dom";



const Assets5 = () => {
  const Api = "http://localhost:9000/financia_banks";
  const params = useParams();
  const [setCarta] = useState([]);

  const [ProductData, setProductData] = useState({});
  useEffect(() => {
    axios
      .get(Api)
      .then((res) => setCarta(res.data))
      .catch((err) => console.log(err));
    axios.get(`${Api}/${params.productId}`).then((response) => {
      const data = response.data;
      setProductData(data);
    });
  }, []);

  const UserId = JSON.parse(localStorage.getItem("login"));
  const [User, setUser] = useState({});

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users/my_balance/${UserId.id}`)
      .then((res) => setUser(res.data))
      .catch((error) => {
        setUser(UserData);
        console.log(error);
      });
  }, []);

  console.log(User);



   const [eneble7, setEneble7] = useState("");
   const [eneble8, setEneble8] = useState("");
  
const UserData = JSON.parse(localStorage.getItem("login"));

   const SaveFunc = () => {
     const UpdatesKeyValues = {
       ...UserData?.personal?.personalbanks,
       eneble7: eneble7,
       eneble8: eneble8,
     };

     localStorage.setItem(
       "login",
       JSON.stringify({
         ...UserData,
         personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
       })
     );

     axios
       .patch(`http://localhost:9000/users/${UserData.id}`, {
         ...UserData,
         personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
       })

       .then(() => alert(`Ok`))
       .catch((error) => {
         alert("error");
         console.log(error);
       });
   };

    const Login = JSON.parse(localStorage.getItem("login"));

    if (Login === null || Login === undefined) window.location.replace("/");


  return (
    <React.Fragment>
      <Accordion>
        <Accordion.Item eventKey="0" className="assets_item">
          <Accordion.Header>
            <div className="assets_title">
              <img className="assets_title_img" src={assets5} alt="" />
              <p className="assets_title_p">PROPERTY VALUE</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="assets_body" key={ProductData.id}>
              <div className="assets_body_block">
                <h6 className="personal_h6">Property value </h6>
                <div className="assets_block">
                  <div className="assets_block_inputs">
                    <p> Outstanding balance</p>
                    <p className="assets_onputs_p">
                    
                      <p className="congrat_carts_div1_h6">
                        £{" "}
                        {Number(ProductData.price_1) +
                          Number(ProductData.price_2) +
                          Number(ProductData.price)}
                      </p>
                    </p>
                    <p>Status</p>
                    <p className="assets_onputs_p">New building </p>
                  </div>
                  <div className="assets_block_inputs">
                    <p>Address</p>
                    <p className="assets_onputs_adress">{User?.address}</p>
                    <p>Holding</p>
                    <p className="assets_onputs_p">No</p>
                  </div>
                </div>
              </div>

              <div className="assets_body_block">
                <h6 className="personal_h6">Property primary</h6>
                <div className="assets_block">
                  <div className="assets_block_inputs">
                    <p>Property type</p>
                    <p className="assets_onputs_p">Townhouse</p>
                  </div>
                  <div className="assets_block_inputs">
                    <p>Zoning</p>
                    <p className="assets_onputs_adress">
                      {User?.banks?.my_balance?.address_home}
                    </p>
                  </div>
                </div>
              </div>

              <div className="assets_5_inputs">
                <input
                  type="checkbox"
                  name="7"
                  id="input"
                  value={eneble7}
                  onClick={(e) => setEneble7(e.target.name)}
                />
                <p className="assets_5_input_p">
                  I confirm that all above information is correct.
                </p>
              </div>
              <div className="assets_5_input">
                <input
                  type="checkbox"
                  name="8"
                  id="assets_5_input_1"
                  value={eneble8}
                  onClick={(e) => setEneble8(e.target.name)}
                />
                <p className="assets_5_input_p">
                  I hereby confirm that the information provided is true to the
                  best of my knowledge, and I am not withholding any
                  information.
                </p>
              </div>

              <div className="personal_buttons">
                <div className="personal_btn">
                  <Link
                    to={`${window.location.origin}/${Login.id}/singleproduct/step7/1`}
                  >
                    <button className="payments_back_b">Back</button>
                  </Link>
                  <Link to={""}>
                    <button
                      className="signing_button_payments"
                      onClick={() => SaveFunc()}
                    >
                      Save
                    </button>
                  </Link>

                  <Link
                    to={`${window.location.origin}/${Login.id}/singleproduct/step9/${ProductData.id}`}
                  >
                    <button className="signing_button_payments">
                      Continue
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Assets5;
