import React, { useEffect } from "react";
import "./Homeacounts.css";

// import { Link } from "react-router-dom";
import summaryicons from "../../Images/icons-02.svg";
import Offcanvasmodal from "../Offcanvasmodal/Offcanvasmodal.jsx";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import SplitButton from "react-bootstrap/SplitButton";

// import st_2 from "../../Images/stander_2.png";
// import st_3 from "../../Images/stander_3.png";
// import st_4 from "../../Images/stander_4.png";

import summaryicons1 from "../../Images/icons-03.svg";

// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import SplitButton from "react-bootstrap/SplitButton";
// import Button from "react-bootstrap/Button";

import { useState } from "react";

import MyVerticallyCenteredModal from "../modal";

import Footer from "../../components/Footer/Footer.jsx";
import { Link } from "react-router-dom";
import axios from "axios";

import Offstikesmodal from "../Offcanvasmodal//Offstikesmodal";
import { Chart } from "chart.js";
import { BarChart } from "./Chartpages";



const Homeacounts = () => {
  const [modalShow, setModalShow] = useState(false);

  const [FilterAccountNumber, setFilterAccountNumber] = useState("Filter");
  const [FilterDate, setFilterDate] = useState("Filter");

  // const params = useParams();
  // const [show, setShow] = useState(false);
  const [User, setUser] = useState({});
  // const [Transfer] = useState(0);
  // const [PayFrom, setPayFrom] = useState("Select");
  // const [PayTo, setPayTo] = useState("Select");

  const Login = JSON.parse(localStorage.getItem("login"));

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  // const TransferFunc = (e) => {
  //   setTransfer(e.target.value);
  //   if (e.target.value > User.banks?.balance) {
  //     setTransfer(User.banks?.balance);
  //     // console.log(Transfer);
  //   }
  // };

  // const RandomAccount = () => {
  //   const random = Math.floor(Math.random() * 99999999);

  //   if (random >= 10000000 && random <= 99999999) {
  //     return random;
  //   } else {
  //     RandomAccount();
  //   }
  // };

  // const PaymentFunc = () => {
  //   const isAgree = window.confirm(`Are your sure? ${Transfer}`);

  //   if (isAgree) {
  //     axios
  //       .patch(`http://localhost:9000/users/${params.userId}`, {
  //         banks: {
  //           ...Login.banks,
  //           balance: Number(User.banks?.balance) - Number(Transfer),
  //           transaction: [
  //             {
  //               title: PayTo,
  //               paid: Transfer,
  //               account: RandomAccount(),
  //             },
  //             ...Login?.banks?.transaction,
  //           ],
  //         },
  //       })
  //       .then(() => {
  //         axios
  //           .get(`http://localhost:9000/users/${params.userId}`)
  //           .then((data) => {
  //             localStorage.setItem("login", JSON.stringify(data.data));
  //             window.location.reload();
  //           });
  //       });
  //   }
  // };

  useEffect(() => {
    Login ?? window.location.replace("/");
    if (Login) {
      setUser(Login);
    }
  }, []);

  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  // const day = currentDate.getDate().toString().padStart(2, "0");

  // const defaultValue = `${year}-${month}-${day}`;
  // const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const [showButton, setShowButton] = useState(false)

  // const togglePopup = () => {
  //   setIsPopupOpen(!isPopupOpen);
  // };

  // setTimeout(() => {
  //   setShowButton(true)
  // }, 10000)

  const [carts, setCarts] = useState([]);
  useEffect(() => {
    axios
      .get(`http://localhost:9000/users`)
      .then((res) => {
        const data = res.data;
        data.forEach((item) => {
          const currentBank = item.banks;
          setCarts(currentBank.account ?? []);
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div className="Homeacounts">
        {/* {showButton ? (<button>Next</button>): undefined} */}

        <Offstikesmodal />
        <>
          <Offcanvasmodal />
        </>

        <div className="Homeacounts_card">
          <div className="main_acount">
            <div className="main_acout_card">
              <div className="acount_bank">
                <p>Main Account</p>
                <h4>Novus Bank Account </h4>
                <p> 83 4323 4323 3432 3432</p>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_22">
                    Transfer Funds
                  </button>
                </Link>
              </div>
              <div className="acount_funds">
                <p>Available Funds</p>
                <h3 className="acount_funds_h33">
                  {" "}
                  {Number(User.banks?.balance)} £
                </h3>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_2">
                    Link Accounts
                  </button>
                </Link>
              </div>
            </div>

            <div className="st_fedd">
              <div className="summary_fedd_icons">
                <div className="summary_icons_1">
                  <img
                    className="transfer_icons_s"
                    src={summaryicons1}
                    alt=""
                  />
                  <h6>Transaction Feed</h6>
                </div>

                <div className="summary_icons_1">
                  <input
                    className="summary_input"
                    type="date"
                    name="1"
                    id="1"
                  />
                </div>
              </div>
              <div className="summary_fedd_title">
                <p className="summary_fedd_title_p">Account number</p>

                <div>
                  {["Secondary"].map((variant) => {
                    return (
                      <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={FilterAccountNumber}
                      >
                        {User?.banks?.transaction?.map((value, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Dropdown.Item
                                eventKey={index}
                                onClick={(e) =>
                                  setFilterAccountNumber(e.target.textContent)
                                }
                              >
                                {value?.account}
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </DropdownButton>
                    );
                  })}
                </div>

                <div>
                  {["Secondary"].map((variant) => (
                    <DropdownButton
                      as={ButtonGroup}
                      key={variant}
                      id={`dropdown-variants-${variant}`}
                      variant={variant.toLowerCase()}
                      title={FilterDate}
                    >
                      {User?.banks?.transaction?.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Dropdown.Item
                              eventKey={i}
                              onClick={(e) =>
                                setFilterDate(e.target.textContent)
                              }
                            >
                              {item?.date}
                            </Dropdown.Item>
                          </React.Fragment>
                        );
                      })}
                    </DropdownButton>
                  ))}
                </div>
              </div>

              <div className="fedd_text">
                <p>Total Amount Spent £1,491.00 in 2023</p>

                {Login?.banks?.transaction?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="fedd_text_1">
                        <p> {item?.title} </p>
                        <p> £{item?.paid || item.my_downpayment}</p>
                      </div>
                      <hr />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="new_acaount_tm">
            <div className="new_acaount_card">
              <h6>Create New Account (TM)</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                incididunt ut labore et dolore{" "}
              </p>

              <button className="main_acounts_button" onClick={HandleLogout}>
                Add New Accounts
              </button>
            </div>

            <div className="div_dd">
             
                <div className="acaund_home">
                  <div className="transfer_icons">
                    <img
                      className="transfer_icons_s"
                      src={summaryicons}
                      alt=""
                    />
                    <h6>Account Summary</h6>
                  </div>

                  <div className="acaund_title">
                    <p className="perrsf">Accounts </p>
                  </div>

                  <hr />
                  <div className="acaund_text_home">
                    <div className="acaund_text-top">
                      <h6>Account Number </h6>
                      <h6>Account Balance</h6>
                    </div>
                    {User?.banks?.transaction?.map((value, index) => {
                      return (
                        <div className="acaund_text-row" key={index}>
                          <div className="account_number">{value.account}</div>
                          <div className="account_balance">
                            £ {value.paid || value.my_downpayment}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              
            </div>
          </div>

          <div className="plass_carder_card">
            {carts
              .sort((a, b) => b.id - a.id)
              .slice(0, 4)
              .map((carts) => {
                return (
                  <React.Fragment key={carts.id}>
                    <div className="stander_carder_1">
                      <div className="st_carder">
                        <div className="st_card_1">
                          <h6> {carts.title} </h6>
                          <p> {carts.number} </p>
                          <h5 className="st_card_1_summa"> {carts.price} £ </h5>
                        </div>

                        <div className="st_card_2">
                          <img
                            className="st_card_img"
                            src={
                              process.env.PUBLIC_URL + "/images/" + carts.icons
                            }
                            alt="error"
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Terms and Conditions"}
          body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
        />

        {/* Bottom  text */}
      </div>

      <Footer />
    </>
  );
};

export default Homeacounts;
