import React from "react";
import "./Financialposition_id.css";
// import axios from "axios";
import Loannavbar from "../../../components/Loannavbar/Loannavbar.jsx";

import Personalcart from "../../../components/Personalcart/Personalcart";


import Personaldetails1 from "../Payments_personal/Personaldetails1";
import Personaldetails2 from "../Payments_personal/Personaldetails2";
import Personaldetails3 from "../Payments_personal/Personaldetails3";
import Personaldetails4 from "../Payments_personal/Personaldetails4";
import Personaldetails5id from "../Payments_personal/Personaldetails5id";


const FinancialpositionId = () => {
 
  return (
    <React.Fragment>
      {" "}
      <Loannavbar />
      <div className="Personaldetails">
        <h5>Welcome - let's get started</h5>
        <p>Tell us about your basic details and account requirements</p>
        <p>Personal info and company info (Additional details)</p>

        <div className="personal_list">
          <div className="personal_actout">
            {/* Accordion 1 Lorem, ipsum  illo pariatur*/}
            <Personaldetails1 />
            {/* Accordion 2 Lorem, ipsum  illo pariatur*/}
            <Personaldetails2 />
            {/* Accordion 3 Lorem, ipsum dolor pariatur*/}
            <Personaldetails3 />
            {/* Accordion 4 Lorem, ipsum dolor pariatur*/}
            <Personaldetails4 />
            {/* Accordion 5 Lorem, ipsum dolor pariatur*/}
            <Personaldetails5id />
          </div>
          <div className="personal_hr"></div>

          <div className="personalcart_div">
            <Personalcart />
          </div>
        </div>

       
      </div>
      <p className="personal_footer">©2022 CBS</p>
    </React.Fragment>
  );
};

export default FinancialpositionId;
