
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Unlinkmodal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlewindow = () => {
    window.location.reload();
  }
   
  return (
    <>
      <Button
        className="add_button_submit"
        variant="primary"
        onClick={handleShow}
      >
        Confirm
      </Button>

      <Modal
        className="unclink_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="unclink_modal">Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to unlink your account? This process can’t be
          undone.
        </Modal.Body>
        <Modal.Footer className="unclink_modal">
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="success" onClick={handlewindow}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Unlinkmodal;
