import React, { useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";

import assets3 from "./Img/assets3.png";

import axios from "axios";
import { Link, useParams } from "react-router-dom";


const Assets3 = () => {
  const Api = "http://localhost:9000/financia_banks";
  const params = useParams();
  const [setCarta] = useState([]);

  const [ProductData, setProductData] = useState({});
  useEffect(() => {
    axios
      .get(Api)
      .then((res) => setCarta(res.data))
      .catch((err) => console.log(err));
    axios.get(`${Api}/${params.productId}`).then((response) => {
      const data = response.data;
      setProductData(data);
    });
  }, []);

 const [setPersonaluser] = useState([]);
 const [User, setUser] = useState({});

 useEffect(() => {
   const UserData = JSON.parse(localStorage.getItem("login"));

   axios
     .get(`http://localhost:9000/users`)
     .then((res) => setPersonaluser(res.data))
     .catch((err) => console.log(err));
   setUser(UserData);
 }, []);

  
  
  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion>
        <Accordion.Item eventKey="0" className="assets_item">
          <Accordion.Header>
            <div className="assets_title">
              <img className="assets_title_img" src={assets3} alt="" />{" "}
              <p className="assets_title_p">INCOME</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="assets_body" key={ProductData.id}>
              <div className="assets_body_block">
                <h6 className="personal_h6">Income </h6>
                <div className="assets_block">
                  <div className="assets_block_inputs">
                    <p>Description of income</p>
                    <p className="assets_onputs_p"> {ProductData.title} </p>
                    <p>Frequency</p>
                    <p className="assets_onputs_p">Monthly</p>
                  </div>
                  <div className="assets_block_inputs">
                    <p>Outstanding balance</p>
                    <p className="assets_onputs_adress">
                      {Number(ProductData.price_1) +
                        Number(ProductData.price_2) +
                        Number(ProductData.price)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <h6>Justification if any </h6>
                <textarea
                  className="asstes_textarea"
                  placeholder="Enter justification"
                  cols="20"
                  rows="7"
                ></textarea>
              </div>
              <div className="financial_buttons">
                <Link
                  className="financial_link"
                  to={`${window.location.origin}/${Login.id}/singleproduct/step5/1`}
                >
                  <button className="financial_previous">
                    <i className="bx bx-left-arrow-alt" id="financial_i"></i>
                    Previous{" "}
                  </button>
                </Link>
                <div>
                  {" "}
                  <Link
                    className="personal_btn_button financial_button"
                    to={`${window.location.origin}/${Login.id}/singleproduct/step7/${ProductData.id}`}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Assets3;
