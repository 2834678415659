import axios from "axios";
import React, { useState, useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

const Personaldetails5 = () => {
  const [carta, setCarta] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCards, setFilteredCards] = useState(carta);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = carta.filter((card) =>
      card.title.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCards(query.trim() === "" ? carta : filtered);
  };
  useEffect(() => {
    axios
      .get(`http://localhost:9000/financia_banks`)
      .then((res) => {
        setCarta(res.data);
        setFilteredCards([...res.data]);
      })
      .catch((err) => console.log(err));
  }, []);

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">5</button> Financial
            Position
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              {/* <Button variant="outline-success">Search</Button> */}
            </Form>

            <h6 className="personal_h6">
              Select your bank from the list below.
            </h6>

            <div className="financial_banks">
              <section className="top_carts">
                <div className="carta_banks_row">
                  {filteredCards.map((card) => (
                    <React.Fragment key={card.id}>
                      <Link
                        className="financial_link"
                        to={`${window.location.origin}/${Login.id}/singleproduct/step1/${card.id}`}
                      >
                        <div className="carta_div">
                          <div className="carta_images">
                            <img
                              className="finance_carta_img"
                              src={
                                process.env.PUBLIC_URL + "/images/" + card.image
                              }
                              alt=""
                            />
                          </div>
                          <p className="carta_div_p">{card.title}</p>
                        </div>
                      </Link>
                    </React.Fragment>
                  ))}
                </div>
                {/* <div className="financie_but">
                  <Link className="financial_link">
                    <button className="financial_button">
                      Next
                      <i
                        className="bx bx-right-arrow-alt"
                        id="financial_i"
                      ></i>{" "}
                    </button>
                  </Link>
                </div> */}
              </section>
            </div>

            <div className="personal_buttons">
              <p className="personal_red">Fields marked with * are mandatory</p>

              <div className="personal_btn">
                <Link to={`${window.location.origin}/${Login.id}/Loandetails`}>
                  <button className="payments_back_b">Back</button>
                </Link>
                <Link to={""}>
                  <button className="signing_button_payments">Save</button>
                </Link>

                <button disabled className="finanse_coutine_btn">
                  Continue
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails5;
