import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Identification from "./Pages/Identification/Identification.jsx";
import Register from "./Pages/register/Register.jsx";
import Verification from "./Pages/Verification/Verification.jsx";
import Verificationselfie from "./Pages/Verificationselfie/Verificationselfie.jsx";
import Creation from "./Pages/Creation/Creation.jsx";
import Success from "./Pages/Success/Success.jsx";
import Successcrate from "./Pages/Successcrate/Successcrate.jsx";
import Overview from "./Pages/Overview/Overview.jsx";
import Homeacounts from "./components/Homeacounts/Homeacounts.jsx";
import Extemalacounts from "./components/Extemalacounts/Extemalacounts.jsx";
import Unlinkacounts from "./components/Unlinkacounts/Unlinkacounts.jsx";
import Loans from "./Pages/Loans/Loans.jsx";
import Loansstep1 from "./components/Loansstep1/Loansstep1.jsx";
import Loansstep2 from "./components/Loansstep2/Loansstep2.jsx";
import Loansstep3 from "./components/Loansstep3/Loansstep3.jsx";
import Loansstep4 from "./components/Loansstep4/Loansstep4.jsx";
import Signupregister from "./Pages/Signupregister/Signupregister.jsx";

import Personaldetails from "./Pages/Payments/Personaldetails/Personaldetails.jsx"
import Contactdetails from "./Pages/Payments/Contactdetails/Contactdetails.jsx"
import Employmentdetails from "./Pages/Payments/Employmentdetails/Employmentdetails.jsx";
import Loandetails from "./Pages/Payments/Loandetails/Loandetails.jsx";
import Financialposition from "./Pages/Payments/Financialposition/Financialposition.jsx";
import FinancialpositionId from "./Pages/Payments/Financialposition_id/Financialposition_id.jsx";
import FinancialpositionIdcards1 from "./Pages/Payments/Financialposition_id_cards1/Financialposition_idcards1.jsx";
import FinancialpositionIdcards3 from "./Pages/Payments/FinancialpositionId_cards_2/FinancialpositionIdcards3.jsx";
import FinancialpositionIdcards4 from "./Pages/Payments/FinancialpositionId_cards_4/FinancialpositionIdcards4.jsx";
import FinancialpositionIdcards5 from "./Pages/Payments/FinancialpositionId_cards_5/FinancialpositionIdcards5.jsx";
import FinancialpositionIdcards6 from "./Pages/Payments/FinancialpositionId_cards_6/FinancialpositionIdcards6.jsx";
import FinancialpositionIdcards7 from "./Pages/Payments/FinancialpositionId_cards_7/FinancialpositionIdcards7.jsx";
import FinancialpositionIdcards8 from "./Pages/Payments/FinancialpositionId_cards_8/FinancialpositionIdcards8.jsx";
import FinancialpositionIdcards9 from "./Pages/Payments/FinancialpositionId_cards_9/FinancialpositionIdcards9.jsx";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/Signupregister" element={<Signupregister />} />
        <Route path="/Identification/" element={<Identification />} />
        <Route path="/Verification/" element={<Verification />} />
        <Route path="/Verificationselfie/" element={<Verificationselfie />} />
        <Route path="/Creation/" element={<Creation />} />
        <Route path="/Success/" element={<Success />} />
        <Route path="/Successcrate" element={<Successcrate />} />
        <Route path="/:userId/Overview/" element={<Overview />} />
        <Route path="/:userId/Homeacounts" element={<Homeacounts />} />
        <Route path="/:userId/Extemalacounts" element={<Extemalacounts />} />
        <Route path="/:userId/Unlinkacounts" element={<Unlinkacounts />} />
        <Route path="/:userId/Loans" element={<Loans />} />
        <Route path="/:userId/Loansstep1" element={<Loansstep1 />} />
        <Route path="/:userId/Loansstep2" element={<Loansstep2 />} />
        <Route path="/:userId/Loansstep3" element={<Loansstep3 />} />
        <Route path="/:userId/Loansstep4" element={<Loansstep4 />} />
        {/*  */}
        <Route path="/:userId/Personaldetails" element={<Personaldetails />} />
        <Route path="/:userId/Contactdetails" element={<Contactdetails />} />
        <Route
          path="/:userId/Employmentdetails"
          element={<Employmentdetails />}
        />
        <Route path="/:userId/Loandetails" element={<Loandetails />} />
        <Route
          path="/:userId/Financialposition"
          element={<Financialposition />}
        />
        <Route
          path="/:userId/singleproduct/step1/:productId"
          element={<FinancialpositionId />}
        />
        <Route
          path="/:userId/singleproduct/step2/:productId"
          element={<FinancialpositionIdcards1 />}
        />
        <Route
          path="/:userId/singleproduct/step3/:productId"
          element={<FinancialpositionIdcards3 />}
        />
        <Route
          path="/:userId/singleproduct/step4/:productId"
          element={<FinancialpositionIdcards4 />}
        />
        <Route
          path="/:userId/singleproduct/step5/:productId"
          element={<FinancialpositionIdcards5 />}
        />
        <Route
          path="/:userId/singleproduct/step6/:productId"
          element={<FinancialpositionIdcards6 />}
        />
        <Route
          path="/:userId/singleproduct/step7/:productId"
          element={<FinancialpositionIdcards7 />}
        />

        <Route
          path="/:userId/singleproduct/step8/:productId"
          element={<FinancialpositionIdcards8 />}
        />
        <Route
          path="/:userId/singleproduct/step9/:productId"
          element={<FinancialpositionIdcards9 />}
        />
      </Routes>
    </>
  );
};

export default App;
