import React from "react";
import "./Loannavbar.css";

import loannavlogo from "../Img/logo1.png";
import loannavuser from "../Img/user-circle.png";

import ProgressBar from "react-bootstrap/ProgressBar";





import navcardimge1 from "../Img/nav-user.png";
import navcardimge2 from "../Img/nav-frame.png";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import My_Future_Home from "../My_Future_Home.jsx";
import { Link } from "react-router-dom";

const Loannavbar = () => {


   const HandleLogout = () => {
     localStorage.removeItem("login");
     window.location.replace("/");
   };
   
  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined ) window.location.replace("/")

  return (
    <React.Fragment>
      <nav>
        <div className="nav_users">
          <div className="nav_block">
            <img className="loannavimge" src={loannavlogo} alt="" />
            <Link
              className="link_loans_tu"
              to={`${window.location.origin}/${Login.id}/Loans`}
            >
              <p className="nav_block_p">Home Loan</p>
            </Link>
          </div>

          <DropdownButton
            className="loaan_users_drown"
            variant="sccuress"
            id="dropdown-basic-button"
            title={<img className="loannavuser" src={loannavuser} alt="" />}
          >
            <Dropdown.Item href="#/action-1">
              <p>Loan Application Number</p> <h5>4543453</h5>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#/action-2">
              <Link
                className="link_loans_tu"
                to={`${window.location.origin}/${Login.id}/Overview`}
              >
                <div className="nav_users_card_icons">
                  <img
                    className="nav_userscardimge"
                    src={navcardimge1}
                    alt=""
                  />
                  <p>My Profile</p>
                </div>
              </Link>
            </Dropdown.Item>

            <Dropdown.Item href="#/action-3">
              {" "}
              <div className="nav_users_card_icons" onClick={HandleLogout}>
                <img className="nav_userscardimge" src={navcardimge2} alt="" />
                <p>Logout</p>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <ProgressBar
          striped
          variant="success"
          className="navanimate"
          animated
          now={100}
        />
        <div className="personal_nav_title">
          <h4 className="loan_nav_h4">Loan Application Form</h4>
          <div className="loan_nav_caart">
            <My_Future_Home />
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Loannavbar;

<DropdownButton variant="sccuress" id="dropdown-basic-button" title="" setOpen>
  <div className="nav_users_card">
    <p className="nav_card_p">Loan Application Number</p>
    <h5> 4543453</h5>
    <hr />

    <div className="nav_users_card_imgess">
      
     
    </div>
  </div>
</DropdownButton>;
