import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import { Link } from "react-router-dom";
import { Context } from "../../../Context";

const Personaldetails4 = () => {
  const { userRegisterObject } = useContext(Context);

  const UserData = JSON.parse(localStorage.getItem("login"));

  const [properties, setProperties] = useState("Select");
  const [employment, setEmployment] = useState("Select");

  const MandatoryInfoFunc = () => {
    if (properties === "" || employment === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  const HandleNext = () => {
    if (properties !== "" && employment !== "") {
      userRegisterObject.personal.personalbanks.property = properties;
      userRegisterObject.personal.personalbanks.employment_type = employment;
      return (
        <Link
          className="signing_button_payments lin_personal_decoration"
          to={`${window.location.origin}/${Login.id}/Financialposition`}
        >
          Continue
        </Link>
      );
    } else {
      return <button className=" signing_button_payments">Continue</button>;
    }
  };

  const [setPersonaluser] = useState([]);
  const [User, setUser] = useState({});

  const SaveFunc = () => {
    const UpdatesKeyValues = {
      ...UserData?.personal?.personalbanks,
      property: properties,
      employment_type: employment,
    };

    localStorage.setItem(
      "login",
      JSON.stringify({
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
    );

    axios
      .patch(`http://localhost:9000/users/${UserData.id}`, {
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })

      .then(() => alert(`Ok`))
      .catch((error) => {
        alert("error");
        console.log(error);
      });
  };

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users`)
      .then((res) => setPersonaluser(res.data))
      .catch((err) => console.log(err));
    setUser(UserData);
  }, []);

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">4</button> Loan Details
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="personal_applicat_steep_2">
              <h6 className="personal_h6">If primary mention any 1</h6>

              <div className="applicant">
                <div className="aplicant_role">
                  <p>What is current estimate interest rate </p>
                  <input
                    className="input_personal_stavka"
                    type="text"
                    placeholder="5"
                    value={User?.banks?.my_balance?.loan_stavka}
                  />

                  <p>Approximately how much property worth</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="£ 500000"
                    value={User?.banks?.my_balance?.loan_home}
                  />

                  <p>How many applicants will be for this loan</p>
                  <input
                    className="input_personal_stavka"
                    type="text"
                    placeholder="Co-appliant 1"
                    value={User?.personal?.application}
                  />
                  <p className="how_loan_part">
                    How many properties will be included as <br /> part of this
                    loan
                  </p>
                  <input
                    className="input_personal_noaplicant"
                    type="text"
                    placeholder="Co-applicant 1"
                    value={User?.personal?.applicationRole}
                  />
                </div>

                <div className="co_aplicant">
                  <p>What is your current loan amount </p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="£ 30000"
                    value={User?.banks?.my_balance?.home_price}
                  />

                  <p>How will this property be used? </p>
                  <>
                    {["Secondary"].map((variant) => (
                      <SplitButton
                        className="personal_dv_2"
                        key={variant}
                        id={`dropdown-split-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={properties}
                        onChange={(e) => setProperties(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setProperties(e.target.outerText)}
                          eventKey="1"
                        >
                          I will live there
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProperties(e.target.outerText)}
                          eventKey="2"
                        >
                          I want to rent a house
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProperties(e.target.outerText)}
                          eventKey="3"
                        >
                          I sell house
                        </Dropdown.Item>
                      </SplitButton>
                    ))}
                  </>

                  <p>What is your employment type? </p>
                  <>
                    {["Secondary"].map((variant) => (
                      <SplitButton
                        className="personal_dv_2"
                        key={variant}
                        id={`dropdown-split-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={employment}
                        onChange={(e) => setEmployment(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setEmployment(e.target.outerText)}
                          eventKey="1"
                        >
                          Employee
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setEmployment(e.target.outerText)}
                          eventKey="2"
                        >
                          Detector
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={(e) => setEmployment(e.target.outerText)}
                          eventKey="3"
                        >
                          Other
                        </Dropdown.Item>
                      </SplitButton>
                    ))}
                  </>
                </div>
              </div>
            </div>

            <div className="personal_applicat_steep_2">
              <h6 className="personal_h6">Co- Applicant-1</h6>

              <div className="applicant">
                <div className="aplicant_role">
                  <p>First Name </p>

                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="David"
                    value={User?.personal?.first_name}
                  />

                  <p>Email address </p>

                  <input
                    className="input_personal_sign"
                    type="email"
                    value={User?.email}
                  />
                </div>

                <div className="co_aplicant">
                  <p>Last Name </p>

                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="Wisner"
                    value={User?.personal?.last_name}
                  />

                  <p>Mobile number </p>

                  <input
                    className="input_personal_sign"
                    type="number"
                    value={User?.phone_number}
                  />
                </div>
              </div>
              <div className="personal_buttons">
                {MandatoryInfoFunc()}

                <div className="personal_btn">
                  <Link
                    to={`${window.location.origin}/${Login.id}/Employmentdetails`}
                  >
                    <button className="payments_back_b">Back</button>
                  </Link>
                  <Link to={""}>
                    <button
                      className="signing_button_payments"
                      onClick={() => SaveFunc()}
                    >
                      Save
                    </button>
                  </Link>

                  {HandleNext()}
                  {/* <Link
                    to={`${window.location.origin}/${Login.id}/Financialposition`}
                  >
                    <button className="signing_button_payments">
                      Continue
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails4;
