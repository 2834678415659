import React, { useEffect, useState } from "react";

// import personalimge from "../../Images/personalimge.png";
import axios from "axios";

const Personalcart = () => {
  const UserId = JSON.parse(localStorage.getItem("login"));
  const [User, setUser] = useState({});

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users/my_balance/${UserId.id}`)
      .then((res) => setUser(res.data))
      .catch((error) => {
        setUser(UserData);
        console.log(error);
      });
  }, []);

  console.log(User);

  const [badshet, setbadshet] = useState([]);

  useEffect(() => {
    axios
      .get(`http://localhost:9000/loansbanks`)
      .then((res) => setbadshet(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <React.Fragment>
      <div className="personal_card">
        <p>My Future Home</p>
        {/* <img src={personalimge} alt="" /> */}

        {badshet.map((badshet) => {
          return (
            <React.Fragment key={badshet.id}>
              <img
                src={process.env.PUBLIC_URL + "/images/" + badshet.icons}
                alt="error"
              />
            </React.Fragment>
          );
        })}
        <div className="presonal_card_title">
          <p>{User?.banks?.my_balance?.address_home}</p>
          <hr />
          <p className="personal_p_klu">Property worth</p>
          <h6>£ {User?.banks?.my_balance?.loan_home ?? "0"} </h6>

          <p className="personal_p_klu">Deposit amount</p>
          <h6 className="personal_carth6">
            {User?.banks?.transaction?.map((value, index) => {
              return (
                <div key={index}>
                  <h6> {value?.my_downpayment} </h6>
                </div>
              );
            })}
          </h6>

          <p className="personal_p_klu">Loan amount</p>
          <h6>£ {User?.banks?.my_balance?.home_price ?? "0"}</h6>

          <p className="personal_p_klu">Rate of interest (P.A)</p>
          <h6> {User?.banks?.my_balance?.loan_stavka ?? "0"} %</h6>

          <p className="personal_p_klu">Loan tenure</p>
          <h6> {User?.banks?.my_balance?.loan_years ?? "0"} years</h6>

          <p className="personal_p_klu">Estimated monthly EMI</p>
          <h6>£ {User?.banks?.my_balance?.instalment ?? "0"} </h6>
        </div>
        {badshet.map((badshet) => {
          return (
            <React.Fragment key={badshet.id}>
              <iframe
                title="ifrname"
                className = "personal_card_iframe"
                src={badshet.carts}
              ></iframe>
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Personalcart;
 

