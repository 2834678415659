import React, { useContext, useState } from "react";
import "./Identification.css";
import logo from "../../Images/logo 1.png";
import { Link } from "react-router-dom";
import { Context } from "./../../Context";
import Footer from "../../components/Footer/Footer";

export default function Identification() {
  const { userRegisterObject } = useContext(Context);

  const [userMobile, setuserMobile] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userAccount, setUserAccount] = useState("");
  const [Next] = useState(false);

  const MandatoryInfoFunc = () => {
    if (userAccount === "" || userMobile === "" || userEmail === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  const UserAccountFunc = (e = Element) => {
    setUserAccount(e.target.value)
    userRegisterObject.personal.applicationRole = e.target.dataset.roleNumber;
  }
const HandleLogout = () => {
  localStorage.removeItem("login");
  window.location.replace("/");
};


  // const MandatoryInfoFunc = () =>
  //   userAccount === "" || userMobile === "" || userEmail === "" ? <p className="input_p">Fields marked with * are mandatory</p> : null;

  const HandleNext = () => {
    // setNext(true);
    if ((userAccount !== "" && userMobile !== "" && userEmail !== "") && !Next) {
      userRegisterObject.phone_number = userMobile;
      userRegisterObject.email = userEmail;
      userRegisterObject.account = userAccount;
      userRegisterObject.personal.application = userAccount;

      return (
        <Link
          className="signing_button Identification_link_but"
          to={"/Verification"}
        >
          Continue
        </Link>
      );
    } else {
      return <button className="signing_button">Continue</button>;
    }
  };

  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="signing">
        <h5 className="signing_h1">Signing up for Online Banking</h5>
        <div className="identification_signing_up">
          <div className="signing_email">
            <h6>Welcome - let's get started</h6>
            <p className="signing_p">
              Tell us about your basic details and account requirements
            </p>
            <h6 className="identification_h6">
              Whom would you like to open an account{" "}
            </h6>
            <div className="input_block">
              <div className="input_text">
                <input
                  id="input"
                  type="radio"
                  name="role"
                  value={"Self"}
                  data-role-number={1}
                  onChange={(e) => UserAccountFunc(e)}
                />
                <p>Self</p>
              </div>

              <div className="input_text">
                <input
                  id="input"
                  type="radio"
                  name="role"
                  value={"Business"}
                  data-role-number={2}
                  onChange={(e) => UserAccountFunc(e)}
                />
                <p>Business</p>
              </div>

              <div className="input_text">
                <input
                  id="input"
                  type="radio"
                  name="role"
                  value={"Family"}
                  data-role-number={3}
                  onChange={(e) => UserAccountFunc(e)}
                />
                <p>Family</p>
              </div>
            </div>
            <div className="input_reg">
              <h6 className="input_indinfactino">
                <span>Your Mobile Number</span>
              </h6>

              <input
                className="input_sgin_1"
                type="number"
                placeholder="Enter mobile number"
                onChange={(e) =>
                  // (userRegisterObject.phone_number = e.target.value)
                  setuserMobile(e.target.value)
                }
                value={userMobile}
              />
              <h6 className="input_indinfactino">
                <span>Your Email</span>
              </h6>
              <input
                className="input_sgin_1"
                type="email"
                placeholder="Enter email address"
                onChange={(e) =>
                  // (userRegisterObject.email = e.target.value)
                  setuserEmail(e.target.value)
                }
                value={userEmail}
              />
              {MandatoryInfoFunc()}
            </div>
          </div>

          <div className="signing_text">
            <h6>Benefits of Online Banking</h6>
            <p>
              Check recent account activity and access account balances from one
              convenient location.
            </p>
            <p>
              Transfer funds between your Novus accounts and accounts at other
              banks.
            </p>
            <p>Go paperless with up to 25 months of deposit statements.</p>
            <h6>Need Assistance?</h6>
            <p>
              For Online Banking technical assistance, Bill Pay support, or
              general account inquiries
            </p>
            <p>Call 1-877-768-2265.</p>
          </div>
        </div>

        <div className="butt_infi">{HandleNext()}</div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
