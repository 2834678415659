import React, { useContext, useState } from "react";
import "./Verification.css";
import logo from "../../Images/logo 1.png";
import { Link } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";

import SplitButton from "react-bootstrap/SplitButton";
import { Context } from "../../Context";

import Footer from "../../components/Footer/Footer";

const Verification = () => {
  const { userRegisterObject } = useContext(Context);

  const [File, setFile] = useState(undefined);
  const [Verification, setVerification] = useState("OTP on your mobile number");
  const [HowVerification, setHowVerification] = useState("Passport");
  const [InputVerification, setInputVerification] = useState("");

  const FileReaderFunc = (e) => {
    const target = e.target;

    const file = target.files[0];

    if (
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/webp" ||
      file.type === "image/svg" ||
      file.type === "image/jfif" ||
      file.type === "image/avif"
    ) {
      const readerFile = new FileReader();

      readerFile.onload = () => {
        setFile(readerFile.result);
      };

      readerFile.onerror = (error) => {
        throw new Error(error);
      };

      readerFile.readAsDataURL(file);
    }
  };

  const MandatoryInfoFunc = () => {
    if (
      File === undefined ||
      Verification === "" ||
      HowVerification === "" ||
      InputVerification === ""
    ) {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };


  const HandleNext = () => {
    if (
      File !== undefined &&
      Verification !== "" &&
      HowVerification !== "" &&
      InputVerification !== ""
    ) {
      
      userRegisterObject.otp.verify = File;
      userRegisterObject.pasport = File;
      userRegisterObject.otp.otp = InputVerification;
      userRegisterObject.otp.verification = `${Verification} / ${HowVerification}`;
      
      return (
        <Link
          className="signing_button_v Identification_link_but"
          to={"/Verificationselfie"}
        >
          Continue
        </Link>
      );
    } else {
      return <button className="signing_button_v">Continue</button>;
    }
  };
  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="signing_v">
        <h5 className="signing_h1">Signing up for Online Banking</h5>

        <div className="vreification_signing_up">
          <>
            <div className="mobile_1_number">
              <p className="signing_p">
                Tell us about your basic details and account requirements
              </p>
              <h6 className="vreification_h6">
                Choose a verification process *
              </h6>

              {[Verification].map((direction) => (
                <SplitButton
                  key={direction}
                  id={`dropdown-button-drop-${direction}`}
                  drop={direction}
                  variant="secondary"
                  title={Verification}
                >
                  <Dropdown.Item
                    eventKey="1"
                    onClick={(e) => setVerification(e.target.outerText)}
                  >
                    OTP on your mobile number
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    onClick={(e) => setVerification(e.target.outerText)}
                  >
                    OTP on your email
                  </Dropdown.Item>
                </SplitButton>
              ))}

              <div className="otp_number">
                <h6 className="vreification_h6">
                  How would you like to verify? *
                </h6>
                {[HowVerification].map((direction) => (
                  <SplitButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={direction}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={(e) => setHowVerification(e.target.outerText)}
                    >
                      Passport
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={(e) => setHowVerification(e.target.outerText)}
                    >
                      Certificate of Merit
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={(e) => setHowVerification(e.target.outerText)}
                    >
                      Other
                    </Dropdown.Item>
                  </SplitButton>
                ))}
              </div>

              <div className="otp_number">
                <h6 className="vreification_h6">Enter your OTP *</h6>
                <input
                  className="vreification_otp_input"
                  type="text"
                  placeholder="Enter your OTP"
                  onChange={(e) => setInputVerification(e.target.value)}
                  value={InputVerification}
                />
              </div>
              {/* @todo */}

              <div className="pasport">
                {File ? (
                  <React.Fragment>
                    <div className="pasport-imageholder">
                      {File === undefined ? "" : <img src={File} alt="error" />}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <label>
                      <input
                        type="file"
                        className="pasport-input-origin"
                        onChange={FileReaderFunc}
                      />

                      <div className="pasport-input-custom">
                        Drag and drop a file
                      </div>
                    </label>
                  </React.Fragment>
                )}
              </div>
              {MandatoryInfoFunc()}
            </div>
          </>

          <div className="vreification_signing_text">
            <h6>Benefits of Online Banking</h6>
            <p>
              Check recent account activity and access account balances from one
              convenient location.
            </p>
            <p>
              Transfer funds between your Novus accounts and accounts at other
              banks.
            </p>
            <p>Go paperless with up to 25 months of deposit statements.</p>
            <h6>Need Assistance?</h6>
            <p>
              For Online Banking technical assistance, Bill Pay support, or
              general account inquiries
            </p>
            <p>Call 1-877-768-2265.</p>
          </div>
        </div>

        <div className="vreification_button">
          <Link to={"../Identification"}>
            <button className="vreification_b">Back</button>
          </Link>

          {HandleNext()}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Verification;
