import React, { useContext, useState } from "react";
import "./Success.css";

import logo from "../../Images/logo 1.png";
import { Link } from "react-router-dom";

import { Context } from "./../../Context";

import Footer from "../../components/Footer/Footer.jsx";
import axios from "axios";

const Success = () => {
  const { userRegisterObject } = useContext(Context);

  // const url = "http://localhost:9000/mobile";
  const API = "http://localhost:9000";

  const [userId, setUserid] = useState("");
  const [userPassword, setUserpassword] = useState("");
  const [userReklama, setUserreklama] = useState("");
  const [userWhatsappopen, setUserwhatsappopen] = useState("");

  const MandatoryInfoFunc = () => {
    if (userId === "" || userPassword === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };


  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  const HandleNext = () => {
    if (
      userId !== "" &&
      userPassword !== ""
    ) {
      const PostData = async () => {
        userRegisterObject.userId = userId;
        userRegisterObject.password = userPassword;
        userRegisterObject.reklama = userReklama;
        userRegisterObject.whatsapp_open = userWhatsappopen;
        localStorage.setItem("login", JSON.stringify(userRegisterObject));
        await axios
          .post(`${API}/users`, userRegisterObject)
          .then(() => alert("Everything's fine"))
          .catch((error) => console.error("Our", error));
      }
      return (
        <Link
          className="success_link_but"
          to={"/Successcrate"}
          onClick={PostData}
        >
          Continue
        </Link>
      );
    } else {
      return "Continue";
    }
  };

  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="success">
        <h5 className="signing_h1">Signing up for Online Banking</h5>
        <p className="sccess_p">
          Tell us about your basic details and account requirements
        </p>
        <div className="success_signing_up">
          <>
            <div className="sccess_mobile">
              <h6 className="sccuess_h6" >New User ID *</h6>

              <input
                className="title_select_input"
                type="text"
                placeholder="Enter user ID"
                value={userId}
                onChange={(e) => setUserid(e.target.value)}
              />
              <h6  className="sccuess_h6" >New Password *</h6>

              <input
                className="title_select_input"
                type="password"
                placeholder="Enter new password"
                value={userPassword}
                onChange={(e) => setUserpassword(e.target.value)}
              />

              <div className="seccess_watsap">
                <input
                  id="input"
                  type="checkbox"
                  name="1"
                  onClick={(e) => setUserreklama(e.target.name)}
                />
                <p>I agree to enable whatsApp alerts.</p>
              </div>

              <div className="seccess_watsap_1">
                <input
                  id="input"
                  type="checkbox"
                  name="2"
                  onClick={(e) => setUserwhatsappopen(e.target.name)}
                />
                <p>
                  I agree to enable promotion emails from <br /> Novus Bank and
                  partners.
                </p>
              </div>
            </div>
          </>

          <div className="sccess_text">
            <h6>Benefits of Online Banking</h6>
            <p>
              Check recent account activity and access account balances from one
              convenient location.
            </p>
            <p>
              Transfer funds between your Novus accounts and accounts at other
              banks.
            </p>
            <p>Go paperless with up to 25 months of deposit statements.</p>
            <h6>Need Assistance?</h6>
            <p>
              For Online Banking technical assistance, Bill Pay support, or
              general account inquiries
            </p>
            <p>Call 1-877-768-2265.</p>
          </div>
        </div>

        {MandatoryInfoFunc()}

        <div className="sccess_button">
          <Link to={"../Creation"}>
            <button className="vreification_b">Back</button>
          </Link>

          {/* <Link to={"../Successcrate"}> */}
          <button className="signing_button_v"> {HandleNext()} </button>
          {/* </Link> */}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Success;
