import React from "react";
import "./Unlinkacounts.css";

import Unlinkmodal from "../Unlinkmodal/Unlinkmodal";

// import { Link } from "react-router-dom";

import MyVerticallyCenteredModal from "../modal";

import { useState, useEffect } from "react";

import axios from "axios";

import deleteunlink from "../Img/delete.png";

import Footer from "../Footer/Footer.jsx";

import Offcanvasmodal from "../Offcanvasmodal/Offcanvasmodal.jsx";
import { Link, useParams } from "react-router-dom";

import Offstikesmodal from "../Offcanvasmodal/Offstikesmodal"

const Unlinkacounts = () => {
  const [modalShow, setModalShow] = useState(false);

  const [usdelete, setUsdelete] = useState([]);
  const [inputDeleteCheck, setInputDeleteCheck] = useState(false);

  // const

  const DeleteInlink = (id = 0) => {
    if (inputDeleteCheck) {
      setUsdelete(usdelete.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    axios
      .get(`http://localhost:9000/users`)
      .then((res) => {
        const data = res.data;
        data.forEach((item) => {
          const currentBank = item.banks;

          setUsdelete(currentBank.account ?? []);
        });
      })
      .catch((err) => console.log(err));
  }, []);

// Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni, est! Consectetur,

const UserData = JSON.parse(localStorage.getItem("login"));

  const SaveFunc = () => {
    const UpdatesKeyValues = {
      ...User?.banks?.account.id
      
    };

    localStorage.setItem(
      "login",
      JSON.stringify({
        ...UserData,
        ...User?.banks?.account, UserData: {
           UpdatesKeyValues 
         }  },
      )
    );

    axios
      .patch(`http://localhost:9000/users/${UserData.id}`, {
        ...UserData,
         ...User?.banks?.account, UserData: { ...User?.banks?.account, UserData: UpdatesKeyValues },
      })
      .then(() => alert(`Ok`))
      .catch((error) => {
        alert("error");
        console.error(error);
      });
  };
   
  
 

  // const SaveFunc = () => {

  //   // User.banks.account: [...usdelete]


  //   const UpdatesKeyValues = {
  //    ...User?.banks?.a
  //   };

  //   localStorage.setItem("login", JSON.stringify(updatedUserData));

  //   axios
  //     .patch(`http://localhost:9000/users/${UserData.id}`, updatedUserData)
  //     .then(() => alert("Data updated successfully"))
  //     .catch((error) => {
  //       alert("Failed to update data");
  //       console.error(error);
  //     });
  // };

  // Call the SaveFunc to trigger the update process
  
  

    const params = useParams();
    const [User, setUser] = useState({});
    const [Transfer, setTransfer] = useState(0);
    const [PayTo] = useState("Select");
    const Login = JSON.parse(localStorage.getItem("login"));

    const HandleLogout = () => {
      localStorage.removeItem("login");
      window.location.replace("/");
    };

    const TransferFunc = (e) => {
      setTransfer(e.target.value);
      if (e.target.value > User.banks?.balance) {
        setTransfer(User.banks?.balance);
        // console.log(Transfer);
      }
    };

   

    // const PaymentFunc = () => {
    //   const isAgree = window.confirm(`Are your sure? ${Transfer}`);

    //   if (isAgree) {
    //     axios
    //       .patch(`http://localhost:9000/users/${params.userId}`, {
    //         banks: {
    //           ...Login.banks,
    //           balance: Number(User.banks?.balance) - Number(Transfer),
    //           transaction: [
    //             {
    //               title: PayTo,
    //               paid: Transfer,
    //               account: RandomAccount(),
    //             },
    //             ...Login?.banks?.transaction,
    //           ],
    //         },
    //       })
    //       .then(() => {
    //         axios
    //           .get(`http://localhost:9000/users/${params.userId}`)
    //           .then((data) => {
    //             localStorage.setItem("login", JSON.stringify(data.data));
    //             window.location.reload();
    //           });
    //       });
    //   }
    // };

    useEffect(() => {
      Login ?? window.location.replace("/");
      if (Login) {
        setUser(Login);
      }
    }, []);
  
  
  return (
    <>
      <div className="unlinkacounts">
        <Offstikesmodal />

        <>
          <Offcanvasmodal />
        </>

        {/* NAV */}

        <div className="Extemalacounts_card">
          <div className="unlink_acount_exen">
            <div className="main_acout_card_exen">
              <div className="acount_bank">
                <p>Main Account</p>
                <h4>Novus Bank Account </h4>
                <p> 83 4323 4323 3432 3432</p>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_unlik_3">
                    Transfer Funds
                  </button>
                </Link>
              </div>
              <div className="acount_funds">
                <p>Available Funds</p>
                <h3 className="acount_funds_h3">
                  {" "}
                  {Number(User.banks?.balance)} £
                </h3>
                <Link to={`${window.location.origin}/${Login.id}/Overview`}>
                  <button className="main_acounts_button_unlik_2">
                    Link Accounts
                  </button>
                </Link>
              </div>
            </div>

            <div className="new_acaount_card_exen">
              <h6>Create New Account (TM)</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                incididunt ut labore et dolore{" "}
              </p>
              <button
                className="main_acounts_button_unlik"
                onClick={HandleLogout}
              >
                Add New Accounts
              </button>
            </div>
          </div>

          <div className="add_unlink_acaound">
            <h6 className="addunlink_ac_h6">Unlink Account</h6>

            <div className="unlink_carder">
              <div className="unlink_carder_title">
                <h6>Bank</h6>
                <h6>Country Code</h6>
                <h6>Action</h6>
              </div>

              <div className="unlink_card_delete">
                {usdelete.slice(0, 4).map((usdelete) => {
                  return (
                    <React.Fragment key={usdelete.id}>
                      <div className="usdelete_card_delet">
                        <input
                          className="unlink_inputt"
                          type="checkbox"
                          onChange={(e) =>
                            setInputDeleteCheck(e.target.checked)
                          }
                        />

                        <img
                          className="usdelete_imge"
                          src={
                            process.env.PUBLIC_URL + "/images/" + usdelete.icons
                          }
                          alt=""
                        />
                        <div className="unlik_card_hp">
                          <h6> {usdelete.title} </h6>

                          <h6> {usdelete.countryCode} </h6>
                        </div>

                        <img
                          className="unlink_card_delete_img"
                          src={deleteunlink}
                          alt="Delete"
                          onClick={() => DeleteInlink(usdelete.id)}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="unlik_buttons">
                <button
                  className="add_button_submit"
                  onClick={() => SaveFunc()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Terms and Conditions"}
          body={`
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
          `}
        />

        {/* Bottom  text */}

        <Footer />
      </div>
    </>
  );
};

export default Unlinkacounts;
