import { createContext } from "react";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const userRegisterObject = {
    account: "",
    phone_number: "998938491920",
    email: "test@it-academy.ru",
    otp: {
      verification: "00000",
      otp: "",
      verify: "",
    },
    pasport: "",
    avatar: "",
    gender: "",
    country: "",
    first_name: "",
    last_name: "",
    address: "",
    birthday: "0000-00-00",
    userId: "",
    password: "",
    icons: "delete.svg",
    banks: {
      my_balance: {
        home_price: 73775,
        my_downpayment: 0,
        instalment: 2951,
        loan_years: 25,
        loan_to: 5,
        loan_stavka: 5,
        loan_home: 50000,
        address_home: "8-10 Lampton Rd, Hounslow TW3 1JL, United Kingdom",
      },
      account: [
        {
          id: 1,
          icons: "santander_1.png",
          status: true,
          countryCode: "UK",
          title: "Santander",
          number: "83 XXXX XX 32",
          price: "1,028.01",
        },
        {
          id: 2,
          icons: "stander_2.png",
          status: true,
          countryCode: "UK",
          title: "Deutsche Bank",
          number: "83 XXXX XX 32",
          price: "228.32",
        },
        {
          id: 3,
          icons: "stander_3.png",
          status: true,
          countryCode: "UK",
          title: "Credit Agricole",
          number: "83 XXXX XX 32",
          price: "123.23",
        },
        {
          id: 4,
          icons: "stander_4.png",
          status: true,
          countryCode: "UK",
          title: "CityBank",
          number: "83 XXXX XX 32",
          price: "107.23",
        },
      ],
      card: "83 4323 4323 3432 3432",
      balance: 1500000,
      transaction: [],
    },

    // personaldels

    personal: {
      application: "",
      applicationRole: "",
      first_name: "",
      last_name: "",
      birthday: "",
      gender: "",
      country: "",
      currentAddress: "",

      personalbanks: {
        postcode: "",

        home_number: "000-00-00",
        workspace: "",
        employer: "",
        sector: "",
        industry: "",
        position: "",
        years: "00",
        mounth: "00",
        bonus_overtime: "",
        bonus_income: "",
        property: "",
        employment_type: "",
        online_id: "",
        passcode: "",
        eneble: "",
        cheking: "",
        saving: "",
        eneble1: "",
        eneble2: "",
        eneble3: "",
        eneble4: "",
        eneble5: "",
        eneble6: "",
        eneble7: "",
        eneble8: "",
        countrycode: "",
        institutionid: "",
        terms: "",
      },

      date: {
        years: 25,
        mounth: 5,
      },
    },
    mobile: [
      {
        userMobile: "+998909556595",
        userEmail: "azizjonusmonaliyev1@gmail.com",
        id: 2,
      },
    ],
  };

  

  return (
    <Context.Provider value={{ userRegisterObject }}>
      {children}
    </Context.Provider>
  );
};

export { ContextProvider, Context };
