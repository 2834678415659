import React, { useContext, useState } from 'react'
import logo from "../../Images/logo 1.png";
import user from "../../Images/user.png"
import { Link } from "react-router-dom";
import "./Verificationselfie.css";
import { Context } from '../../Context';
import Footer from '../../components/Footer/Footer';

// import Dropdown from "react-bootstrap/Dropdown";

// import SplitButton from "react-bootstrap/SplitButton";
const Verificationselfie = () => {

  const HandleLogout = () => {
    localStorage.removeItem("login");
    window.location.replace("/");
  };

  const {userRegisterObject} = useContext(Context)
  const [File, setFile] = useState(undefined)

  const FileReaderFunc = (e) => {
    const target = e.target;

    const file = target.files[0]

    if (
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/webp" ||
      file.type === "image/svg" ||
      file.type === "image/jfif" ||
      file.type === "image/avif"
    ) {
      const readerFile = new FileReader();

      readerFile.onload = () => {
        setFile(readerFile.result);
      }

      readerFile.onerror = (error) => {
        throw new Error(error)
      };

      readerFile.readAsDataURL(file)
    }
      
  }

  const HandleNext = () => {
    userRegisterObject.avatar = File ?? ""
  }

  return (
    <React.Fragment>
      <div className="novus_logo">
        <img className="img" src={logo} alt="" />
        <Link className="link_loans_tu" onClick={HandleLogout}>
          <h6 className="novus_logo_h6">LOGIN</h6>
        </Link>
      </div>
      <div className="signing_selfie">
        <h5 className="vreificationselfie__h1">Signing up for Online Banking</h5>

        <div className="vreificationselfie_up">
          <>
            <div className="vreificationselfie_cards">
              <h6 className="signing_selek">Select an option</h6>
              <h6 className="take_selfie">Take a selfie </h6>
              <div className="verificationselfie_images">
                <label className="verificationselfie_label">
                  <input
                    type="file"
                    className="origin-file"
                    onChange={FileReaderFunc}
                  />
                  <img
                    className="verificationselfie_img custom-file"
                    src={File ?? user}
                    alt=""
                  />
                </label>
                <hr className="verificationselfie_hr" />
              </div>
            </div>
          </>

          <div className="erificationselfie_text">
            <h6>Benefits of Online Banking</h6>
            <p>
              Check recent account activity and access account balances from one
              convenient location.
            </p>
            <p>
              Transfer funds between your Novus accounts and accounts at other
              banks.
            </p>
            <p>Go paperless with up to 25 months of deposit statements.</p>
            <h6>Need Assistance?</h6>
            <p>
              For Online Banking technical assistance, Bill Pay support, or
              general account inquiries
            </p>
            <p>Call 1-877-768-2265.</p>
          </div>
        </div>

        <div className="verificationselfie_button">
          <Link to={"../Verification"}>
            <button className="vreification_b">Back</button>
          </Link>

          <Link to={"/Creation"} onClick={HandleNext}>
            <button className="signing_button_v">Continue</button>
          </Link>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Verificationselfie

