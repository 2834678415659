import React, { useContext, useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import { Context } from "../../../Context";
import { Link } from "react-router-dom";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import axios from "axios";

const Personaldetails1 = () => {
  const { userRegisterObject } = useContext(Context);

  const [personaluser, setPersonaluser] = useState([]);
  const [User, setUser] = useState({});

  const [applicantrole, setapplicantrole] = useState("Select");
  const [ofcoapplicant, setofcoapplicant] = useState("Select");
  const [persgerder, setpersgerder] = useState("Select");
  const [perscity, setperscity] = useState("Select");
  const [persfirtsname, setpersfirtsname] = useState("");
  const [perslastname, setperslastname] = useState("");
  const [persmiddle, setpersmiddle] = useState("");
  const [persbirthday, setpersbirthday] = useState("");
  const [persdrving, setpersdrving] = useState("Select");
  const [persdrvingnumber, setPersdrvingnumber] = useState("");
  const [persdrvingbirthday, setPersdrvingbirthday] = useState("");
  const [persdocument, setPersdocument] = useState("Select");
  const [certificate, setCertificate] = useState("");
  const [dependent, setDependent] = useState("");
  const [persirn, setPersirn] = useState("");
  const [Next] = useState(false);

  const [stavka, setStavka] = useState("Select");

  const MandatoryInfoFunc = () => {
    if (
      stavka === ""
    ) {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  // const MandatoryInfoFunc = () =>
  //   stavka === ""  ? <p className="input_p">Fields marked with * are mandatory</p> : null;

  
    const HandleNext = () => {
      if (
        stavka !== "" 
      ) {
       userRegisterObject.personal.personalbanks.onerwip = stavka;

       return (
         <Link
           className="signing_button_payments lin_personal_decoration"
           to={`${window.location.origin}/${Login.id}/Contactdetails`}
         >
           Continue
         </Link>
       );
      } else {
       return <button className=" signing_button_payments">Continue</button>;
      }
    };
  


  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("login"));

    axios
      .get(`http://localhost:9000/users`)
      .then((res) => setPersonaluser(res.data))
      .catch((err) => console.log(err));
    setUser(UserData);
  }, []);

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");

  return (
    <React.Fragment>
      <Accordion>
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">1</button> Personal Details
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="personal_applicat_steep_1">
              <div className="applicant">
                <div className="aplicant_role">
                  <p>Applicant Role</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="Co-applicant 1"
                    value={User?.personal?.application}
                  />
                </div>

                <div className="co_aplicant">
                  <p>No. Of Co-applicant</p>
                  <input
                    className="input_personal_noaplicant"
                    type="text"
                    placeholder="Co-applicant 1"
                    value={User?.personal?.applicationRole}
                  />
                </div>
              </div>

              <div className="Ownership">
                <p>Ownership % of Co-applicant 1 </p>
                <>
                  {[stavka].map((direction) => (
                    <SplitButton
                      key={direction}
                      id={`dropdown-button-drop-${direction}`}
                      drop={direction}
                      variant="secondary"
                      title={Number(User?.banks?.my_balance?.loan_stavka)}
                    >
                      <Dropdown.Item
                        eventKey="1"
                        onClick={(e) => setStavka(e.target.outerText)}
                      >
                        3 %
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={(e) => setStavka(e.target.outerText)}
                      >
                        5 %
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="3"
                        onClick={(e) => setStavka(e.target.outerText)}
                      >
                        10 %
                      </Dropdown.Item>
                    </SplitButton>
                  ))}
                </>
              </div>
            </div>

            <div className="personal_applicat_steep_2">
              <h6 className="personal_h6">Primary Applicant</h6>

              <div className="applicant">
                <div className="aplicant_role">
                  <p>Firts Name</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="David"
                    value={User?.personal?.first_name}
                    onChange={(e) => setpersfirtsname(e.target.value)}
                  />

                  <p>Last Name</p>
                  <input
                    className="input_personal_sign"
                    type="text"
                    placeholder="Wisner"
                    value={User?.personal?.last_name}
                    onChange={(e) => setperslastname(e.target.value)}
                  />

                  <p>Garder</p>
                  <>
                    {[SplitButton].map((DropdownType, idx) => (
                      <DropdownType
                        className="personal_dv"
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={User?.personal?.gender}
                        // value={}
                        onChange={(e) => setpersgerder(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setpersgerder(e.target.outerText)}
                          eventKey="1"
                        >
                          {" "}
                          Male{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setpersgerder(e.target.outerText)}
                          eventKey="2"
                        >
                          Female
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setpersgerder(e.target.outerText)}
                          eventKey="3"
                        >
                          Other
                        </Dropdown.Item>
                      </DropdownType>
                    ))}
                  </>
                </div>

                <div className="co_aplicant">
                  <p> Email</p>
                  <input
                    className="input_personal_sign"
                    type="email"
                    placeholder="Enter email"
                    value={User?.email}
                    onChange={(e) => setpersmiddle(e.target.value)}
                  />

                  <p> Date of Birth </p>

                  <input
                    className="input_personal_sign"
                    type="date"
                    value={User?.personal?.birthday}
                    onChange={(e) => setpersbirthday(e.target.value)}
                  />

                  <p>City</p>
                  <>
                    {[SplitButton].map((DropdownType, idx) => (
                      <DropdownType
                        className="personal_dv_pers"
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={User?.personal?.country}
                        // value={"country"}
                        onChange={(e) => setperscity(e.target.value)}
                      >
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="1"
                        >
                          Uzbekistan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="2"
                        >
                          Russia
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="3"
                        >
                          Kazakhstan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="4"
                        >
                          Kyrgyzstan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="5"
                        >
                          Tajikistan
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="6"
                        >
                          Turkey
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="7"
                        >
                          Arabic
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setperscity(e.target.outerText)}
                          eventKey="8"
                        >
                          American
                        </Dropdown.Item>
                      </DropdownType>
                    ))}
                  </>
                </div>
              </div>

              <div className="personal_buttons">
                {MandatoryInfoFunc()}
                <div className="personal_btn">
                  <Link to={""}>
                    <button disabled className="finanse_coutine_btn">
                      Save
                    </button>
                  </Link>

                  {HandleNext()}
                  {/* <Link
                    to={`${window.location.origin}/${Login.id}/Contactdetails`}
                  >
                    <button className="signing_button_payments">
                      Continue
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails1;
