import React, { useEffect, useState } from "react";
import "../FinancialpositionId_cards_4/FinancialpositionIdcards4.css";

import Accordion from "react-bootstrap/Accordion";

import assets1 from "./Img/assets1.png";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const Assets1 = () => {

    // const Users = "http://localhost:9000/users";

 const Api = "http://localhost:9000/financia_banks";
 // const [userCountry, setUsercountry] = useState("Select");
 const params = useParams();
 const [ setCarta] = useState([]);

      const [ProductData, setProductData] = useState({});
      // useEffect(() => {
      //   axios
      //     .get(Users)
      //     .then((res) => setCarta(res.data))
      //     .catch((err) => console.log(err));
      //   axios.get(`${Users}/${params.productId}`).then((response) => {
      //     const data = response.data;
      //     setProductData(data);
      //   });
      // }, []);
    
    
     useEffect(() => {
       axios
         .get(Api)
         .then((res) => setCarta(res.data))
         .catch((err) => console.log(err));
       axios.get(`${Api}/${params.productId}`).then((response) => {
         const data = response.data;
         setProductData(data);
       });
     }, []);


// bazadan olingan api

   const [setPersonaluser] = useState([]);
   const [User, setUser] = useState({});

   useEffect(() => {
     const UserData = JSON.parse(localStorage.getItem("login"));

     axios
       .get(`http://localhost:9000/users`)
       .then((res) => setPersonaluser(res.data))
       .catch((err) => console.log(err));
     setUser(UserData);
   }, []);
     
  
    const Login = JSON.parse(localStorage.getItem("login"));

    if (Login === null || Login === undefined) window.location.replace("/");
    
  return (
    <React.Fragment>
      <Accordion>
        <Accordion.Item eventKey="0" className="assets_item">
          <Accordion.Header>
            <div className="assets_title">
              <img className="assets_title_img" src={assets1} alt="" />{" "}
              <p className="assets_title_p">ASSETS</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="assets_body" key={ProductData.id}>
              <div className="assets_body_block">
                <h6 className="personal_h6">Real Estate Assets</h6>
                <div className="assets_block">
                  <div className="assets_block_inputs">
                    <p>Asset address</p>
                    <p className="assets_onputs_adress">{User?.address}</p>
                    <p>Value</p>
                    <p className="assets_onputs_p">
                      £ {User?.banks?.my_balance?.loan_home}
                    </p>
                  </div>
                  <div className="assets_block_inputs">
                    <p>Income from rental</p>
                    <p className="assets_onputs_p">£ {ProductData.price_1} </p>
                  </div>
                </div>
              </div>
              <div className="">
                <h6>Justification if any </h6>
                <textarea
                  className="asstes_textarea"
                  placeholder="Enter justification"
                  cols="20"
                  rows="7"
                ></textarea>
              </div>

              <div className="assets_body_block">
                <h6 className="personal_h6">Other assets </h6>
                <div className="assets_block">
                  <div className="assets_block_inputs">
                    <p>Bank name</p>
                    <p className="assets_onputs_p"> {ProductData.title} </p>
                    <p>Cash in hand</p>
                    <p className="assets_onputs_p">£ {ProductData.price}</p>
                    <p>Total</p>
                    <p className="assets_onputs_p">
                      £{" "}
                      {Number(ProductData.price_1) +
                        Number(ProductData.price_2) +
                        Number(ProductData.price)}
                    </p>
                  </div>
                  <div className="assets_block_inputs">
                    <p>Saving Account</p>
                    <p className="assets_onputs_adress">
                      £ {ProductData.price_2}{" "}
                    </p>
                    <p>Fixed deposit</p>
                    <p className="assets_onputs_p">£ {ProductData.price_1} </p>
                  </div>
                </div>
              </div>
              <div className="financial_buttons">
                <Link
                  className="financial_link"
                  to={`${window.location.origin}/${Login.id}/singleproduct/step3/1`}
                >
                  <button className="financial_previous">
                    <i className="bx bx-left-arrow-alt" id="financial_i"></i>
                    Previous{" "}
                  </button>
                </Link>
                <div>
                  {" "}
                  <Link
                    className="personal_btn_button financial_button"
                    to={`${window.location.origin}/${Login.id}/singleproduct/step5/${ProductData.id}`}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Assets1;
