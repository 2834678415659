import axios from "axios";
import React, { useState, useEffect, useContext } from "react";

import Accordion from "react-bootstrap/Accordion";

import { Link, useParams } from "react-router-dom";
import { Context } from "../../../Context";

const Personaldetails5id = () => {
  const Api = "http://localhost:9000/financia_banks";
  const { userRegisterObject } = useContext(Context);

  const UserData = JSON.parse(localStorage.getItem("login"))

  const params = useParams();

  const [carta, setCarta] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCards, setFilteredCards] = useState(carta);
  const [ProductData, setProductData] = useState({});

  const [inpvalue, setInpvalue] = useState("");
  const [inpvalue1, setInpvalue1] = useState("");
  const [Next] = useState(false);

  const MandatoryInfoFunc = () => {
    if (inpvalue === "" || inpvalue1 === "") {
      return <p className="input_p">Fields marked with * are mandatory</p>;
    }
  };

  const Login = JSON.parse(localStorage.getItem("login"));

  if (Login === null || Login === undefined) window.location.replace("/");


  const SaveFunc = () => {
    const UpdatesKeyValues = {
      ...UserData?.personal?.personalbanks,
      online_id: inpvalue,
      passcode: inpvalue1,
    };

    localStorage.setItem(
      "login",
      JSON.stringify({
        ...UserData,
        personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
      })
    );

    axios.patch(`http://localhost:9000/users/${UserData.id}`, {
      ...UserData,
      personal: { ...UserData?.personal, personalbanks: UpdatesKeyValues },
    })
      .then(() => alert(`Ok`))
      .catch((error) => {
        alert("error");
        console.log(error);
      });

  }


  // const MandatoryInfoFunc = () =>
  //   userAccount === "" || userMobile === "" || userEmail === "" ? <p className="input_p">Fields marked with * are mandatory</p> : null;

  const HandleNext = () => {
    // setNext(true);
    if (inpvalue !== "" && inpvalue1 !== "" && !Next) {
      userRegisterObject.online_id = inpvalue;
      userRegisterObject.passcode = inpvalue1;

      return (
        <Link
          className="personal_btn_button financial_button"
          to={`${window.location.origin}/${Login.id}/singleproduct/step2/${ProductData.id}`}
        >
          Next
        </Link>
      );
    } else {
      return (
        <button className="financial_button_btn">
          Next <i className="bx bx-right-arrow-alt" id="financial_i"></i>{" "}
        </button>
      );
    }
  };
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = carta.filter((card) =>
      card.title.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCards(query.trim() === "" ? carta : filtered);
  };
  useEffect(() => {
    axios
      .get(Api)
      .then((res) => setCarta(res.data))
      .catch((err) => console.log(err));
    axios.get(`${Api}/${params.productId}`).then((response) => {
      const data = response.data;
      setProductData(data);
    });
  }, []);

  return (
    <React.Fragment>
      <Accordion className="Accordion">
        <Accordion.Item className="personal_actout_title" eventKey="0">
          <Accordion.Header className="personal_actout_tit">
            <button className="personal_acount_btn">5</button> Financial
            Position
          </Accordion.Header>
          <Accordion.Body className="personal_actout_body">
            <div className="Financial_id_body">
              <React.Fragment key={ProductData.id}>
                <div className="ProductData_div">
                  <div className="carta_div">
                    <div className="carta_images">
                      <img
                        className="finance_carta_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/" +
                          ProductData.image
                        }
                        alt=""
                      />
                    </div>
                    <p className="carta_div_p">{ProductData.title}</p>
                  </div>
                </div>
              </React.Fragment>
              <p className="ProductData_p">
                By continuing, you agree to
                <Link to={"/404"}>
                  <span className="ProductData_p_span">
                    {" "}
                    Yodlee's Terms of Use{" "}
                  </span>{" "}
                </Link>
                for account linking. Your application provider’s privacy notice
                applies to our handling of your data.
              </p>

              <div className="personalid_onlineid">
                <h6 className="personalid_onlineid_h6"> Online ID</h6>
                <input
                  className="personalid_onlineid_input"
                  type="text"
                  placeholder="Enter online ID "
                  onChange={(e) => setInpvalue(e.target.value)}
                />
                <h6 className="personalid_onlineid_h6"> Passcode</h6>
                <input
                  personalid_onlineid
                  className="personalid_onlineid_input"
                  type="number"
                  placeholder="Enter passcode"
                  value={inpvalue1}
                  onChange={(e) => setInpvalue1(e.target.value)}
                />
                <button
                  className="personalid_onlineid_btn"
                  onClick={() => SaveFunc()}
                >
                  Submit
                </button>
              </div>
            </div>

            <div className="financial_buttons">
              <Link
                className="financial_link"
                to={`${window.location.origin}/${Login.id}/Financialposition`}
              >
                <button className="financial_previous">
                  <i className="bx bx-left-arrow-alt" id="financial_i"></i>
                  Previous{" "}
                </button>
              </Link>
              <div>{HandleNext()}</div>
            </div>
            <div className="personal_buttons">
              {MandatoryInfoFunc()}
              {/* <div className="personal_btn">
                <Link
                  to={`${window.location.origin}/${Login.id}/Financialposition`}
                >
                  <button disabled className="finanse_coutine_btn">
                    Back
                  </button>
                </Link>
                <Link to={""}>
                  <button className="signing_button_payments" onClick={() => SaveFunc() } >Save</button>
                </Link>

                <Link to={""}>
                  <button disabled className="finanse_coutine_btn">
                    Continue
                  </button>
                </Link>
              </div> */}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Personaldetails5id;
