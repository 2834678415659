import React from 'react'
import "./FinancialpositionIdcards7.css"
import Loannavbar from "../../../components/Loannavbar/Loannavbar";
import Personaldetails1 from "../Payments_personal/Personaldetails1";
import Personaldetails2 from "../Payments_personal/Personaldetails2";
import Personaldetails3 from "../Payments_personal/Personaldetails3";
import Personaldetails4 from "../Payments_personal/Personaldetails4";

import Personalcart from "../../../components/Personalcart/Personalcart";

import Personaldetails5idcards7 from '../Payments_personal/Personaldetails5idcards7';


const FinancialpositionIdcards7 = () => {
  return (
    <React.Fragment>
      {" "}
      <Loannavbar />
      <div className="Personaldetails">
        <h5>Welcome - let's get started</h5>
        <p>Tell us about your basic details and account requirements</p>
        <p>Personal info and company info (Additional details)</p>

        <div className="personal_list">
          <div className="personal_actout">
            {/* Accordion 1 Lorem, ipsum  illo pariatur*/}
            <Personaldetails1 />
            {/* Accordion 2 Lorem, ipsum  illo pariatur*/}
            <Personaldetails2 />
            {/* Accordion 3 Lorem, ipsum dolor pariatur*/}
            <Personaldetails3 />
            {/* Accordion 4 Lorem, ipsum dolor pariatur*/}
            <Personaldetails4 />
            {/* Accordion 5 Lorem, ipsum dolor pariatur*/}
            {/* <Personaldetails5id />
            Accordion 5id_1  Lorem, ipsum dolor pariatur */}
            {/* <Personaldetails5idcards1 />
            Accordion 5id_2  Lorem, ipsum dolor pariatur */}
            {/* <Personaldetails5idcards3 /> */}
            {/* Accordion 5id_4 Lorem, ipsum dolor pariatur */}
            {/* <Personaldetails5idcards4 /> */}
            {/* Accordion 5id_4 Lorem, ipsum dolor pariatur */}

            {/* <Personaldetails5idcards5 /> */}
            {/* Accordion 5id_4 Lorem, ipsum dolor pariatur */}
            {/* <Personaldetails5idcards6 /> */}
            {/* Accordion 5id_4 Lorem, ipsum dolor pariatur */}
            <Personaldetails5idcards7 />
          </div>
          <div className="personal_hr"></div>

          <div className="personalcart_div">
            <Personalcart />
          </div>
        </div>

       
      </div>
      <p className="personal_footer">©2022 CBS</p>
    </React.Fragment>
  );
}

export default FinancialpositionIdcards7
